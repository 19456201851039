define('entities/caccounts/routingaction_routinggroup',[
    'app',
    'backbone',
    'underscore',
    'module',
    'dynamic',
    'settings',
    'jquery',
    'entities/caccounts/routinggroup',
    'entities/caccounts/routingaction'
], function (
    App,
    Backbone,
    _,
    module,
    Dynamic,
    Settings,
    $,
    RoutingGroups
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.RoutingActionRoutingGroup = Backbone.RelationalModel.extend({
        defaults: {
            'sequence': null,
            'position': null,
            'refRoutingGroup': null,
            'color': null,
            'rackId': null,
            'condition': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refRoutingGroup',
            relatedModel: 'RoutingGroup',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'routingAction',
            relatedModel: 'RoutingAction',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getEntity: function () {
            return module.exports;
        },
        findSamples: function () {
            var url = Settings.url('compuzz', 'v2/routingaction/' + this.get('routingAction').get('secId') + '/routingGroup/' + this.get('refRoutingGroup').get('secId') + '/samples');
            var defer = $.Deferred();
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                contentType: 'application/json',
                success: _.bind(function (data) {
                    require(['entities/caccounts/samples'], function (Samples) {
                        defer.resolve(Samples.getCollection(data));
                    });
                }, this)
            });
            return defer.promise();
        }
    });

    app.ns.RoutingActionRoutingGroupCollection = Backbone.Collection.extend({
        model: app.ns.RoutingActionRoutingGroup,
        comparator: 'sequence'
    });

    App.reqres.setHandler('routingActionRoutingGroup:model', function (itemJSON) {
        if (!itemJSON) {
            itemJSON = {};
        }
        return app.ns.RoutingActionRoutingGroup.findOrCreate(itemJSON);
    });

    App.reqres.setHandler('routingActionRoutingGroup:collection', function (itemJSON) {
        var collection = new app.ns.RoutingActionRoutingGroupCollection(itemJSON);
        collection.sort();
        return collection;
    });

    module.exports = _.defaults({
        getName: function () {
            return 'routingActionRoutingGroup';
        },
        getDynamicJson: function () {
            return 'RoutingAction_RoutingGroupJson';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.RoutingActionRoutingGroup.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        getCreateEditFieldValidate: function () {
            var ignored = [];
            ignored.push({name: 'sequence', type: 'required'});
            return ignored;
        },
        getCreateEditLine: function (model) {
            return [
                {'field': 'sequence', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-6 m-r-2'}}},
                {
                    'field': 'refRoutingGroup',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {
                            'getAutocompleteParam': RoutingGroups.getAutocompleteParam
                        },
                        'context': RoutingGroups,
                        'display': {'class': 'col-w-20 m-l-2 m-r-2'}
                    }
                },
                {'field': 'color', 'param': {'type': 'COLOR', 'display': {'class': 'col-w-17 m-l-2 m-r-2'}}},
                {'field': 'position', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-6 m-r-2'}}},
                {'field': 'showPopup', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-8 p-l-5 p-r-5'}}},
                {'field': 'rackId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-21 m-l-2 m-r-2'}}},
                {
                    'field': 'refRoutingGroup.condition',
                    'dependsOn': {
                        'model': model,
                        'field': 'refRoutingGroup'
                    },
                    'param': {
                        'type': 'TEXTAREA',
                        'display': {'class': 'col-w-50 m-l-2 m-r-2'},
                        'readOnly': true
                    }
                }
            ];
        }
    }, Dynamic);
});
