define('sampleCreateEditLayoutView',[
    'module',
    'dialogFormView',
    'template!sampleCreateEditLayoutView',
    'savingBehavior',
    'backbone',
    'underscore',
    'jquery',
    'app',
    'settings',
    'moment',
    'wellUtils',
    'prepRunWellDetailView',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns',
    'samplePrepWellCollection',
    'samplePcrWellCollection',
    'sampleResultCollection',
    'entities/caccounts/samples',
    'rolesMixin',
    'entities/caccounts/lisana',
    'dynamicListToList',
    'sampleCreateEditHeaderView'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    Backbone,
    _,
    $,
    App,
    Settings,
    moment,
    WellUtils,
    PrepWellDetailView,
    PcrWells,
    PcrRuns,
    SamplePrepWellCollection,
    SamplePcrWellCollection,
    SampleResultCollection,
    Samples,
    RolesMixin,
    LisAnas,
    DynamicListToList,
    SampleHeader
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            sampleDt: '.date-sampleDt',
            confirm: '.js-confirm',
            prepWellsDisplay: '.js-prepwell-region-display',
            pcrWellsDisplay: '.js-pcrwell-region-display',
            resultsDisplay: '.js-results-region-display'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click .js-show-sample-prepWell': 'onShowPrepWell',
            'click .js-show-pcrWell-from-prepWell': 'onShowPcrWellFromPrepWell',
            'click .js-show-pcrWell': 'onShowPcrWell',
            'click .js-sampleClass-refresh': 'onClickSampleClassRecalc',
            'click .js-validation-refresh': 'onClickValidationRefresh',
            'click .js-order': 'showOrder',
            'click .js-edit-lisana': 'onChangeLisAna'
        },

        regions: {
            displayResult: '.js-display-results-region',
            prepWells: '.js-prepwell-region',
            results: '.js-results-region',
            pcrWells: '.js-pcrwell-region',
            header: '.js-header-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            error: 'onSaveError',
            change: 'render'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'}
        ],

        onClickSampleClassRecalc: function () {
            $('.js-global-loader').show();
            this.model.recalcSampleClass().always(_.bind(function () {
                $('.js-global-loader').hide();
            }, this));
        },

        onClickValidationRefresh: function () {
            $('.js-global-loader').show();
            this.model.refreshValidation().always(_.bind(function () {
                $('.js-global-loader').hide();
            }, this));
        },

        onShowPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var dataSecId = $target.attr('data-secId');
            $('.js-global-loader').show();

            var well = this.model.get('prepWells').findWhere({secId: dataSecId});
            well.get('refPrepRun').fetch()
                .done(_.bind(function () {
                    var wellDetailView = new PrepWellDetailView({
                        model: well,
                        extractionMethodModel: well.get('refPrepRun').get('refExtractionMethod')
                    });
                    wellDetailView.show({
                        title: well.get('runName'), //todo check value
                        className: 'popupPrepWellView'
                    });
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        serializeData: function () {
            var templateData = {};
            templateData.responseQuery = this.model.get('responseQuery');

            templateData.lisAnas = this.model.get('lisAnas').map(function (lisAna) {
                return {invalid: lisAna.get('entityError'), code: lisAna.get('code')};
            });
            templateData.assayGroups = this.model.get('assayGroups').pluck('code');
            templateData.targets = this.model.get('targets').pluck('code');
            templateData.lisAnaOpenedUpdated = this.model.get('lisAnaOpenedUpdated') ? moment(this.model.get('lisAnaOpenedUpdated')).format('YYYY-MM-DD HH:mm') : '-';
            templateData.canRefreshSampleClass = Settings.get('currentUserModel').hasRole(RolesMixin.DASHBOARD_ADMIN_SAMPLECLASS) ? '' : 'invisible';

            templateData.tracing = this.model.get('routingGroupHistories').map(function (trac) {
                var json = {};
                json.date = moment(trac.get('creatDate')).format('YYYY-MM-DD HH:mm');
                json.user = trac.get('creatBy').get('nickName');
                if (trac.get('creatBy').get('lastName') && trac.get('creatBy').get('firstName')) {
                    json.title = trac.get('creatBy').get('lastName') + ' ' + trac.get('creatBy').get('firstName');
                } else {
                    json.title = trac.get('creatBy').get('email');
                }
                json.routingActionRoutingGroup = trac.get('routingActionRoutingGroup').get('routingAction').get('code') + ' - ' + trac.get('routingActionRoutingGroup').get('refRoutingGroup').get('code');
                return json;
            });

            templateData.assayIcons = WellUtils.getPrepWellTooltipAssaysIcons();
            templateData.patient = this.model.get('order') ? this.model.get('order').get('patientInformation') ? this.model.get('order').get('patientInformation') : '-' : '-';
            return templateData;
        },

        onRender: function () {
            this.getRegion('header').show(new SampleHeader({
                model: this.model
            }));
            if (this.model.get('prepWells').isEmpty()) {
                this.ui.prepWellsDisplay.addClass('hidden');
            } else {
                this.getRegion('prepWells').show(new SamplePrepWellCollection({
                    collection: this.model.get('prepWells')
                }));
            }
            if (this.model.get('results').isEmpty()) {
                this.ui.resultsDisplay.addClass('hidden');
            } else {
                var collection = new Backbone.Collection();
                this.options.sampleResultDefinitions.map(_.bind(function (sampleResultDefinition) {
                    var model = new Backbone.Model();
                    model.set('sampleResultDefinition', sampleResultDefinition);
                    model.set('result', _.first(this.model.get('results').filter(function (result) {
                        return result.get('refSampleResultDefinition') === sampleResultDefinition;
                    })));
                    collection.push(model);
                }, this));
                this.getRegion('results').show(new SampleResultCollection({
                    collection: this.model.get('results')
                }));
            }

            if (this.model.get('pcrWells').isEmpty()) {
                this.ui.pcrWellsDisplay.addClass('hidden');
            } else {
                this.getRegion('pcrWells').show(new SamplePcrWellCollection({
                    collection: this.model.get('pcrWells')
                }));
            }
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.save().done(_.bind(function () {
                    this.box.modal('hide');
                }, this));
            }
        },

        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.box.modal('hide');
            }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },
        onShowPcrWellFromPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var dataSecId = $target.attr('data-secId');

            $('.js-global-loader').show();

            var prepWell = this.model.get('prepWells').filter(function (prepWell) {
                return prepWell.get('pcrWells').findWhere({secId: dataSecId});
            });
            var pcrWell = _.first(prepWell).get('pcrWells').findWhere({secId: dataSecId});
            var pcrRunModel = pcrWell.get('refPcrRun');
            pcrRunModel.fetch().done(_.bind(function () {
                var pcrWellModel = pcrRunModel.get('wells').findWhere({secId: dataSecId});
                require(['pcrWellController'], _.bind(function (PcrWellController) {
                    PcrWellController.showDetails(pcrWellModel, null, null);
                }, this));
            }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },
        onShowPcrWell: function (e) {
            var $target = $(e.currentTarget);
            var dataSecId = $target.attr('data-secId');

            $('.js-global-loader').show();

            var pcrWell = this.model.get('pcrWells').findWhere({secId: dataSecId});
            var pcrRunModel = pcrWell.get('refPcrRun');
            pcrRunModel.fetch().done(_.bind(function () {
                var pcrWellModel = pcrRunModel.get('wells').findWhere({secId: dataSecId});
                require(['pcrWellController'], _.bind(function (PcrWellController) {
                    PcrWellController.showDetails(pcrWellModel, null, null);
                }, this));
            }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        showOrder: function () {
            require(['ordersController'], _.bind(function (controller) {
                this.model.get('order').fetch().done(_.bind(function () {
                    controller.showDetails(this.model.get('order'));
                }, this));
            }, this));
        },

        onChangeLisAna: function () {
            LisAnas.findAll(this.model.get('refLIS')).done(_.bind(function (collection) {
                var view = new DynamicListToList({
                    'model': this.model,
                    'field': 'lisAnas',
                    'values': this.model.get('lisAnas').models,
                    items: collection.models,
                    codeListCode: ''
                });
                this.listenTo(view, 'listToList:confirm', _.bind(this.listToListConfirm, this, 'lisAnas'));
                view.show({
                    title: _.i18n('sample.lisAna.listToList'),
                    className: 'baseTableEditAddPopup'
                });
            }, this));
        },
        listToListConfirm: function (fieldName, itemsSelected) {
            var items = itemsSelected.map(function (itemSelected) {
                return LisAnas.getModel({secId: itemSelected.get('secId')});
            });
            this.model.get('lisAnas').reset();
            this.model.get('lisAnas').push(items);

            var loader = $('.js-global-loader');
            loader.show();
            this.model.save().always(function () {
                loader.hide();
            });
        }
    });
});
