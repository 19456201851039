define('prepWellToolTipView',[
    'module',
    'marionette',
    'template!prepWellToolTipView',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: Tpl,

        serializeData: function () {
            var well = this.model;

            var templateData = {
                smpType: well.get('smpType'),
                repeatStatus: well.get('repeatStatus'),
                pooled: well.get('pooled'),
                pos: well.get('pos'),
                smpId: well.get('smpId').get('code'),
                dilution: well.get('dilution')
            };

            var extractionKitsLots = [];

            _.each(well.get('refPrepRun').get('reagentExtractionKitLots'), _.bind(function (extractionKitLot, reagentType) {
                extractionKitsLots.push({
                    lotId: extractionKitLot && extractionKitLot.refExtractionKitLot ? extractionKitLot.refExtractionKitLot.code : '-',
                    code: extractionKitLot && extractionKitLot.refExtractionKit ? extractionKitLot.refExtractionKit.code : '-',
                    reagentType: reagentType
                });
            }, this));

            templateData.extractionKitsLots = extractionKitsLots;

            templateData.pcrKitsLots = [];

            _.each(well.get('kitLotByKits'), _.bind(function (item) {
                var kitLots = [];
                var pcrKit = {};

                if (item.refPcrKit && item.refPcrKit.code) {
                    pcrKit.code = item.refPcrKit.code;
                }
                _.each(item.pcrKitLots, _.bind(function (pcrKitLot) {
                    kitLots.push(pcrKitLot.code);
                }, this));

                pcrKit.kitLots = kitLots.join(';');
                templateData.pcrKitsLots.push(pcrKit);
            }, this));

            templateData.wellsPooled = this.options.wellsPooled;

            templateData.wellsPool = this.options.wellsPool;

            templateData.assaysToDisplay = this.options.assaysToDisplay;

            templateData.concatComment = '';
            if (well.get('smpId').get('lisComment')) {
                templateData.concatComment = well.get('smpId').get('lisComment') + ' ';
            }
            if (well.get('smpId').get('comment')) {
                templateData.concatComment = templateData.concatComment + well.get('smpId').get('comment');
            }
            return templateData;
        }
    });
});

