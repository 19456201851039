define('pcrWellCurveToolTipView',[
    'module',
    'marionette',
    'template!pcrWellCurveToolTipView',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: Tpl,


        serializeData: function () {
            var well = this.model;

            if (!well.get('smpId').get('name') && well.get('refPcrWell') && well.get('refPcrWell').get('smpId').get('name')) {
                well = well.get('refPcrWell');
            }

            var templateData = {
                smpType: well.get('smpType') ? well.get('smpType') : '',
                repeatStatus: well.get('repeatStatus'),
                pooled: well.get('pooled'),
                pos: well.get('pos'),
                smpId: well.get('smpId') ? well.get('smpId').get('code') : '',
                assay: well.get('refAssay') ? well.get('refAssay').get('code') : '',
                valWst: well.get('valWst'),
                dilution: well.get('dilution')
            };

            var wellResToDisplay = _.first(well.getDisplayGroup());

            if (wellResToDisplay && wellResToDisplay.get('mbAna') && wellResToDisplay.get('mbAna').get('code')) {
                templateData.hiddenResult = wellResToDisplay.get('mbAna').get('code');
            } else {
                templateData.hiddenResult = 'displayAll';
            }

            templateData.targetsResultsIcons = well.getTargetsResultIcons();

            templateData.smpIdFormated = well.get('smpId').get('shortId');

            templateData.wellResNumFormatted = (wellResToDisplay && wellResToDisplay.get('cts') &&
                Array.isArray(wellResToDisplay.get('cts'))) ?
                wellResToDisplay.get('cts').join(';') :
                (wellResToDisplay && wellResToDisplay.get('cts') ? wellResToDisplay.get('cts') : '');
            templateData.errorWarning = well.get('results').any(_.bind(function (wellTarget) {
                return wellTarget.get('codeErr') !== null && wellTarget.get('codeErr') !== undefined;
            }, this));

            templateData.wellsPooled = this.options.wellsPooled;

            templateData.wellsPool = this.options.wellsPool;

            templateData.concatComment = '';
            if (well.get('smpId').get('lisComment')) {
                templateData.concatComment = well.get('smpId').get('lisComment') + ' ';
            }
            if (well.get('smpId').get('comment')) {
                templateData.concatComment = templateData.concatComment + well.get('smpId').get('comment');
            }
            return templateData;

        }

    });
});

