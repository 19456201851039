define('runExportPlateLayoutCyclerView',[
    'module',
    'backbone',
    'marionette',
    'dialogFormView',
    'template!runExportPlateLayoutCyclerTpl',
    'savingBehavior',
    'underscore',
    'app',
    'jquery',
    'bootbox',
    'settings',
    'customBootboxMessage',
    'runRemoteFolderView',
    'autocompleteView',
    'entities/caccounts/cyclerpublics',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns'
], function (
    module,
    Backbone,
    Marionette,
    DialogFormView,
    runExportPlateLayoutTpl,
    SavingBehavior,
    _,
    App,
    $,
    Bootbox,
    Settings,
    CustomBootboxMessage,
    RunRemoteFolderView,
    AutocompleteView,
    CyclerPublics
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: runExportPlateLayoutTpl,

        ui: {
            input: '.js-info-input',
            exportFileToCentralLib: '.exportFileToCentralLib',
            exportFileToLocal: '.exportFileToLocal',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',

            'click @ui.exportFileToCentralLib': 'onExportFileToCentralLib',
            'click @ui.exportFileToLocal': 'onExportFileToLocal'
        },

        regions: {
            selectCycler: '.js-select-cycler-region'
        },

        fieldsToValidate: [
            {name: 'refCycler', type: 'required'}
        ],

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('refCycler', this.options.model.get('refCyclerPublic'));
        },

        onShow: function () {
            $.material.init();
            this.showSelectCyclerView();
        },

        showSelectCyclerView: function () {
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerPublics.getAutocompleteParam({
                    modelProperty: 'refCycler',
                    readOnly: true
                }))
            );
            this.getRegion('selectCycler').show(view);
        },

        onExportFileToCentralLib: function () {
            this.options.model.exportPlateLayout({downloadFile: false}).done(function () {
                CustomBootboxMessage.customThumbUpNotification();
            })
                .fail(function () {
                    CustomBootboxMessage.customThumbDownNotification();
                })
                .always(_.bind(function () {
                    this.box.modal('hide');
                }, this));
        },

        onExportFileToLocal: function () {
            this.options.model.exportPlateLayout({downloadFile: true}).done(function (isFile, fileName, fileContent) {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
                element.setAttribute('download', fileName);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            });
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            this.destroy();
        }
    });
});
