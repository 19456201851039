define('dynamicCreateEditComponent',[
    'module',
    'app',
    'formView',
    'template!dynamicCreateEditComponent',
    'underscore',
    'jquery',
    'settings',
    'marionette',
    'backbone',
    'dynamicCreateEditInteger',
    'dynamicCreateEditFloat',
    'dynamicCreateEditText',
    'dynamicCreateEditTextarea',
    'dynamicCreateEditLabel',
    'dynamicCreateEditTypeParam',
    'dynamicCreateEditSelect',
    'dynamicCreateEditDate',
    'dynamicCreateEditBoolean',
    'dynamicCreateEditEnum',
    'dynamicCreateEditReference',
    'dynamicCreateEditAutocomplete',
    'dynamicCreateEditCodeList',
    'dynamicCreateEditFile',
    'dynamicCreateEditCustomListToString',
    'dynamicCreateEditCustomListToEntity',
    'dynamicCreateEditCustomListToList',
    'dynamicCreateEditCustomCyclerCustomIO',
    'dynamicCreateEditCustomAssayConfigurationResultFromAssay',
    'dynamicCreateEditCustomSampleList',
    'dynamicCreateEditCustomLisConfiguration',
    'dynamicCreateEditCustomColor',
    'dynamicCreateEditCustomRoutingActionState',
    'dynamicExtractionMethodExtractor',
    'dynamicCreateEditTags',
    'arrayInputView',
    'entities/admin/codelist'
], function (
    module,
    App,
    FormView,
    tpl,
    _,
    $,
    Settings,
    Marionette,
    Backbone,
    DynamicCreateEditInteger,
    DynamicCreateEditFloat,
    DynamicCreateEditText,
    DynamicCreateEditTextarea,
    DynamicCreateEditLabel,
    DynamicCreateEditTypeParam,
    DynamicCreateEditSelect,
    DynamicCreateEditDate,
    DynamicCreateEditBoolean,
    DynamicCreateEditEnum,
    DynamicCreateEditReference,
    DynamicCreateEditAutocomplete,
    DynamicCreateEditCodeList,
    DynamicCreateEditFile,
    DynamicCreateEditCustomListToString,
    DynamicCreateEditCustomListToEntity,
    DynamicCreateEditCustomListToList,
    DynamicCreateEditCustomCyclerCustomIO,
    DynamicCreateEditCustomAssayConfigurationResultFromAssay,
    DynamicCreateEditCustomSampleList,
    DynamicCreateEditCustomLisConfiguration,
    DynamicCreateEditCustomColor,
    DynamicCreateEditCustomRoutingActionState,
    DynamicExtractionMethodExtractor,
    DynamicCreateEditTags,
    ArrayListView,
    CodeLists
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        regions: {
            componentParent: '.js-region-component-parent'
        },

        className: 'dynamic-region',

        attributes: function () {
            return {
                'data-field-name': this.options.fieldName
            };
        },

        serializeData: function () {
            var templateData = {};
            templateData.label = this.options.fieldName === 'codeName' ? 'common.code.name' : this.model.getEntity().getName() + '.' + this.options.fieldName + '.name';
            templateData.dataFieldName = this.options.fieldName;
            templateData.displayClass = this.options.dynamicConfigurationField && this.options.dynamicConfigurationField.display && this.options.dynamicConfigurationField.display['class'] ? this.options.dynamicConfigurationField.display['class'] : 'col-xs-8';
            templateData.extraCount = this.options.dynamicConfigurationField && this.options.dynamicConfigurationField.extra ? (_.isArray(this.options.dynamicConfigurationField.extra) ? this.options.dynamicConfigurationField.extra.length : 1) : 0;
            return templateData;
        },

        onRender: function () {
            var region = this.addRegion('js-region-component', '.js-region-component');
            var dynamicConfigurationField = this.options.dynamicConfigurationField;
            var fieldName = this.options.fieldName;
            this.listenTo(this.model, 'change:' + this.options.fieldName, _.bind(this.render, this));
            if (dynamicConfigurationField.dependsOn) {
                this.listenTo(dynamicConfigurationField.dependsOn.model, 'change:' + dynamicConfigurationField.dependsOn.field, _.bind(this._displayRegion, this, dynamicConfigurationField, region, fieldName));
            }

            this._displayRegion(dynamicConfigurationField, region, fieldName);
            if (dynamicConfigurationField.extra) {
                var dataFieldName = this.options.fieldName;
                if (_.isArray(dynamicConfigurationField.extra)) {
                    _.each(dynamicConfigurationField.extra, _.bind(function (extra, index) {
                        var displayClass = extra.display && extra.display['class'] ? extra.display['class'] : '';
                        var region = this.addRegion('js-region-component-' + index, '.js-region-component-' + index);
                        region.$el.addClass(displayClass);
                        region.$el.attr(dataFieldName, dataFieldName);
                        this._displayRegion(extra, region, fieldName);
                    }, this));
                } else {
                    var displayClassExtra = dynamicConfigurationField.extra.display && dynamicConfigurationField.extra.display['class'] ? dynamicConfigurationField.extra.display['class'] : '';
                    var regionExtra = this.addRegion('js-region-component-0', '.js-region-component-0');
                    regionExtra.$el.addClass(displayClassExtra);
                    regionExtra.$el.attr(dataFieldName, dataFieldName);
                    this._displayRegion(dynamicConfigurationField.extra, regionExtra, fieldName);
                }
            }
        },

        _displayRegion: function (dynamicConfigurationField, region, fieldName) {
            var defer = $.Deferred();
            var view = null;
            switch (dynamicConfigurationField.type) {
                case 'INTEGER':
                    view = new DynamicCreateEditInteger({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'FLOAT':
                    view = new DynamicCreateEditFloat({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'LABEL':
                    view = new DynamicCreateEditLabel({
                        'field': fieldName,
                        'model': this.model,
                        'readOnly': dynamicConfigurationField.readOnly ? dynamicConfigurationField.readOnly : false,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'STRING':
                    view = new DynamicCreateEditText({
                        'field': fieldName,
                        'model': this.model,
                        'readOnly': dynamicConfigurationField.readOnly ? dynamicConfigurationField.readOnly : false,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'TEXTAREA':
                    view = new DynamicCreateEditTextarea({
                        'field': fieldName,
                        'model': this.model,
                        'readOnly': dynamicConfigurationField.readOnly ? dynamicConfigurationField.readOnly : false,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'TYPEPARAM':
                    view = new DynamicCreateEditTypeParam({
                        'fieldName': fieldName,
                        model: this.model,
                        getAutocompleteParam: CodeLists.getAutocompleteParamForCode({codeListCode: dynamicConfigurationField.codeListCode}),
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'SELECT':
                    view = new DynamicCreateEditSelect({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'values': this.model.getValues(fieldName),
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'TAGS':
                    view = new DynamicCreateEditTags({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'ENUM':
                    view = new DynamicCreateEditEnum({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'values': dynamicConfigurationField.values,
                        'width': dynamicConfigurationField.width ? dynamicConfigurationField.width : '150px',
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'REFERENCE':
                    require([
                        'entities/' + this.findEntityByJsonName(dynamicConfigurationField.context, dynamicConfigurationField.refClass)
                    ], _.bind(function (entityReferenceName) {
                        view = new DynamicCreateEditReference({
                            'field': fieldName,
                            'model': this.model,
                            'entity': this.model.getEntity(),
                            'entityReferenceName': entityReferenceName,
                            'getAutocompleteParam': dynamicConfigurationField.config ? dynamicConfigurationField.config.getAutocompleteParam : {},
                            'context': entityReferenceName,
                            'display': dynamicConfigurationField.display
                        });
                        defer.resolve(view);
                    }, this));
                    break;
                case 'AUTOCOMPLETE_SYNC':
                    dynamicConfigurationField.config.getAutocompleteParam(undefined, undefined, this.model).done(_.bind(function (config) {
                        view = new DynamicCreateEditAutocomplete({
                            'field': fieldName,
                            'model': this.model,
                            'entity': this.model.getEntity(),
                            'getAutocompleteParam': config,
                            'display': dynamicConfigurationField.display
                        });
                        defer.resolve(view);
                    }, this));
                    break;
                case 'BOOLEAN':
                    view = new DynamicCreateEditBoolean({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'DATE':
                    view = new DynamicCreateEditDate({
                        'field': fieldName,
                        'value': this.model.get(fieldName)
                    });
                    defer.resolve(view);
                    break;
                case 'ARRAYLIST':
                    if (dynamicConfigurationField.config) {
                        view = dynamicConfigurationField.config();
                    } else {
                        view = new ArrayListView({
                            model: this.model,
                            field: {field: fieldName, param: dynamicConfigurationField},
                            readOnly: dynamicConfigurationField ? dynamicConfigurationField.readOnly : false,
                            disableRender: true
                        });
                    }
                    defer.resolve(view);
                    break;
                case 'CODELIST':
                    view = new DynamicCreateEditCodeList({
                        'fieldName': dynamicConfigurationField.field,
                        'model': this.model,
                        config: {
                            'modelProperty': dynamicConfigurationField.field,
                            'code': dynamicConfigurationField.code,
                            showAll: dynamicConfigurationField.showAll
                        },
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'EXTRACTIONMETHOD_EXTRACTOR':
                    view = new DynamicExtractionMethodExtractor({
                        'model': this.model
                    });
                    defer.resolve(view);
                    break;
                case 'LIST2STRING':
                    view = new DynamicCreateEditCustomListToString({
                        'field': fieldName,
                        'values': this.model.get(fieldName)
                    });
                    defer.resolve(view);
                    break;
                case 'LIST2ENTITY':
                    if (!(this.model.get(fieldName) instanceof Backbone.Collection)) {
                        alert('Error: the field ' + fieldName + ' is not a collection');
                        view = null;
                    } else {
                        view = new DynamicCreateEditCustomListToEntity({
                            'field': fieldName,
                            'collection': this.model.get(fieldName)
                        });
                    }
                    defer.resolve(view);
                    break;
                case 'LISTTOLIST':
                    view = new DynamicCreateEditCustomListToList({
                        'field': fieldName,
                        'model': this.model
                    });
                    defer.resolve(view);
                    break;
                case 'CYCLERCUSTOMIO':
                    view = new DynamicCreateEditCustomCyclerCustomIO({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url
                    });
                    defer.resolve(view);
                    break;
                case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                    view = new DynamicCreateEditCustomAssayConfigurationResultFromAssay({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url
                    });
                    defer.resolve(view);
                    break;
                case 'SAMPLELIST':
                    view = new DynamicCreateEditCustomSampleList({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url
                    });
                    defer.resolve(view);
                    break;
                case 'LISCONFIGURATION':
                    view = new DynamicCreateEditCustomLisConfiguration({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url
                    });
                    defer.resolve(view);
                    break;
                case 'COLOR':
                    view = new DynamicCreateEditCustomColor({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'ROUTINGACTION_STATE':
                    view = new DynamicCreateEditCustomRoutingActionState({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model.get(fieldName),
                        'url': dynamicConfigurationField.url
                    });
                    defer.resolve(view);
                    break;
                case 'FILE':
                    view = new DynamicCreateEditFile({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
            }
            defer.promise().done(_.bind(function () {
                if (dynamicConfigurationField && dynamicConfigurationField.field && dynamicConfigurationField.param && dynamicConfigurationField.field.param.onChange) {
                    view.onChange = _.bind(function (p1, p2) {
                        dynamicConfigurationField.field.param.onChange(p1, p2, this.model);
                    }, this);
                }
                region.show(view);
            }, this));
        },

        findEntityByJsonName: function (context, objectJsonName) {
            if (context === 'CACCOUNT') {
                switch (objectJsonName.toLowerCase()) {
                    case 'assayjson':
                        return 'caccounts/assay';
                    case 'assaygroupjson':
                        return 'caccounts/assaygroup';
                    case 'biogroupjson':
                        return 'caccounts/biogroups';
                    case 'speciejson':
                        return 'caccounts/species';
                    case 'colorcompensationjson':
                        return 'caccounts/colorCompensations';
                    case 'colorcompensationmatricejson':
                        return 'caccounts/colorCompensationMatrices';
                    case 'kitprotjson':
                        return 'caccounts/kitprots';
                    case 'sampleclassjson':
                        return 'caccounts/sampleClass';
                    case 'lisjson':
                        return 'caccounts/lis';
                    case 'mbanajson':
                        return 'caccounts/mbanas';
                    case 'mbanaresjson':
                        return 'caccounts/mbanares';
                    case 'mbanaresgrjson':
                        return 'caccounts/mbanaresgrs';
                    case 'cyclerjson':
                        return 'caccounts/cyclers';
                    case 'lmbcyclerjson':
                        return 'caccounts/lmbcyclers';
                    case 'extractorjson':
                        return 'caccounts/extractors';
                    case 'lmbextractorjson':
                        return 'caccounts/lmbextractors';
                    case 'fileformatplatelayoutjson':
                        return 'caccounts/fileFormatPlateLayouts';
                    case 'fileformatsamplelistjson':
                        return 'caccounts/fileFormatSampleLists';
                    case 'samplehandlerjson':
                        return 'caccounts/samplehandlers';
                    case 'lmbsamplehandlerjson':
                        return 'caccounts/lmbsamplehandlers';
                    case 'lisanajson':
                        return 'caccounts/lisana';
                    case 'lisbiogroupjson':
                        return 'caccounts/lisBioGroups';
                    case 'lisspeciejson':
                        return 'caccounts/lisSpecies';
                    case 'extractionmethodjson':
                        return 'caccounts/extractionMethod';
                    case 'extractionmethodreagentjson':
                        return 'caccounts/extractionMethodReagent';
                    case 'supplierjson':
                        return 'caccounts/suppliers';
                    case 'kitprotconfigurationjson':
                        return 'caccounts/kitprotconfiguration';
                    case 'pcrkitjson':
                    case 'pcrkitdetailsjson':
                        return 'caccounts/kitspcr';
                    case 'pcrkitlotjson':
                        return 'caccounts/kitlotpcr';
                    case 'extractionkitjson':
                    case 'extractionkitdetailsjson':
                        return 'caccounts/kitsextraction';
                    case 'extractionkitlotjson':
                        return 'caccounts/kitlotextraction';
                    case 'cyclerpublicfromcaccountjson':
                        return 'caccounts/cyclerpublics';
                    case 'routingactionjson':
                        return 'caccounts/routingaction';
                    case 'routingaction_routinggroupjson':
                        return 'caccounts/routingaction_routinggroup';
                    case 'routingaction_statejson':
                        return 'caccounts/routingaction_state';
                    case 'usercodelistjson':
                        return 'caccounts/userCodelist';
                    case 'standardcurvejson':
                        return 'caccounts/standardCurve/standardCurves';
                    case 'standardcurvesamplejson':
                        return 'caccounts/standardCurve/standardCurveSamples';
                    case 'standardcurveassayconfigurationresultjson':
                        return 'caccounts/standardCurve/standardCurveAssayConfigurationResults';
                    case 'standardcurveassayconfigurationresultvaluejson':
                        return 'caccounts/standardCurve/standardCurveAssayConfigurationResultValues';
                }
            }
            if (context === 'ADMIN') {
                switch (objectJsonName.toLowerCase()) {
                    case 'cyclerpublicjson':
                    case 'cyclerpubliccodename':
                        return 'admin/cyclers';
                    case 'detectionformatjson':
                        return 'admin/detectionFormat';
                    case 'dyejson':
                        return 'admin/dye';
                    case 'codelistjson':
                        return 'admin/codelist';
                    case 'codelistelementjson':
                        return 'admin/codelistelement';
                    case 'arrayjson':
                        return 'admin/array';
                    case 'arrayelementjson':
                        return 'admin/arrayelement';
                }
            }
            if (context === 'GLOBAL') {
                switch (objectJsonName.toLowerCase()) {
                    case 'userpidglobaljson':
                        return 'global/userpids';
                    case 'caccountjson':
                        return 'global/caccounts';
                    case 'caccounttypejson':
                        return 'global/caccounttypes';
                    case 'logjson':
                        return 'global/logs';
                }
            }
            console.error('Entity not found for the context: ' + context + ' and the JsonNAme: ' + objectJsonName);
        }
    });
});

