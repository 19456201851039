define('editWellView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!editWellView',
    'savingBehavior',
    'bootbox',
    'underscore',
    'jquery',
    'app',
    'settings',
    'colorUtils',
    'autocompleteView',
    'editWellTargetCollectionView',
    'wellDisplaySampleView',
    'entities/caccounts/assay',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/pcrwells'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    EditWellTpl,
    SavingBehavior,
    bootbox,
    _,
    $,
    App,
    Settings,
    colorUtils,
    AutocompleteView,
    WellTargetCollectionView,
    WellDisplaySampleView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: EditWellTpl,
        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            tags: '.js-tags',
            wellComment: '.js-wellComment',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'change @ui.tags': 'onTagsChange',
            'change @ui.wellComment': 'onSampleCommentChange',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        modelEvents: {
            error: 'onSaveError'
        },

        regions: {
            wellTargetRegion: '.js-wellTarget-region',
            wellDisplaySample: '.js-well-sample'
        },

        initialize: function (options) {
            this.popupMode = options.popupMode;
        },

        serializeData: function () {
            var templateData = {
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                pos: this.model.get('pos'),
                assayCode: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '',
                smpId: {
                    code: this.model.get('smpId').get('code')
                },
                runName: this.model.get('refPcrRun').get('name'),
                valWst: this.model.get('valWst'),
                repeatStatus: this.model.get('repeatStatus'),
                tags: this.model.get('tags')
            };

            templateData.errorWarning = this.model.get('results').any(_.bind(function (r) {
                return r.get('codeErr') && r.get('codeErr') !== null && r.get('codeErr') !== '';
            }, this));
            templateData.popupMode = this.popupMode;

            templateData = _.extend(templateData, this.model.getErrorObject());

            var tags = this.model.get('tags');
            if (tags.length === 1 && tags[0] !== '' || tags.length > 1) {
                templateData.tags = tags.join(';');
            }

            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.setPermissions(Settings.get('currentUserModel').hasType('MRES2'));
        },

        onRender: function () {
            this.collection = new Backbone.Collection();
            if (this.model.get('refAssay')) {
                var assay = this.model.get('refPcrRun').findAssayBySecId(this.model.get('refAssay').get('secId'));
                if (assay) {
                    this.model.get('results').each(_.bind(function (result) {
                        if (result.get('refAssayResult').get('type') === this.options.modelResult.get('refAssayResult').get('type')) {
                            this.collection.push(result);
                        }
                    }, this));
                }
            }

            var tempCollection = new WellTargetCollectionView({
                collection: this.collection,
                wellResColor: this.wellResColor
            });
            this.getRegion('wellTargetRegion').show(tempCollection);

            var view = new WellDisplaySampleView({
                model: this.model
            });
            this.getRegion('wellDisplaySample').show(view);
            App.trigger('forClipboard', this.ui.copyParameters);
        },

        onTagsChange: function (e) {
            var $target = $(e.currentTarget),
                tags = $target.val();

            var model = {
                'tags': tags.split(/[,;\s]/)
            };
            this.model.set(model, {silent: true});
            this.triggerMethod('enable:cancel:confirm');
        },

        onSampleCommentChange: function (e) {
            var $target = $(e.currentTarget),
                sampleComment = $target.val();

            var model = {
                'sampleComment': sampleComment
            };
            this.model.set(model, {silent: true});
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            $('.js-global-loader').show();
            var results = this.collection.map(function (model) {
                return {
                    refAssayResult: {
                        id: model.get('refAssayResult').get('id'),
                        type: model.get('refAssayResult').get('type'),
                        code: model.get('refAssayResult').get('code'),
                        name: model.get('refAssayResult').get('name'),
                        isOverAllResult: model.get('refAssayResult').get('isOverAllResult')
                    },
                    cts: _.map(model.get('cts'), function (ct) {
                        return parseFloat(ct);
                    }),
                    quantifications: _.map(model.get('quantifications'), function (quantification) {
                        return parseFloat(quantification);
                    }),
                    result: model.get('result') ? model.get('result').toJSON() : null,
                    comment: model.get('comment'),
                    codeErr: model.get('codeErr')
                };
            });
            this.model.saveResults(results)
                .done(_.bind(this.onSave, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onSave: function () {
            this.box.modal('hide');
        },

        onCancel: function () {
            $('.js-global-loader').show();
            this.model.fetch().always(_.bind(function () {
                this.box.modal('hide');
                $('.js-global-loader').hide();
            }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
});

