define('entities/caccounts/pcrwellresults',[
    'app',
    'backbone',
    'settings',
    'wellResultIconView',
    'jquery',
    'underscore',
    'module',
    'entities/caccounts/userpids',
    'entities/caccounts/assayResultVersion',
    'entities/caccounts/mbanas',
    'entities/caccounts/mbanares',
    'entities/caccounts/curves',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    WellResultIconView,
    $,
    _,
    module
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PcrWellResult = Backbone.RelationalModel.extend({
        subModelTypes: {
            'CURVE': 'PcrWellResultCurve',
            'HL7': 'PcrWellResultHL7',
            'MAGPIX': 'PcrWellResultMagPix',
            'OTHER': 'PcrWellResultOther',
            'CALCULATED': 'PcrWellResultCalculated',
            'SPO_OVAR': 'PcrWellResultSPOOvar',
            'OVAR': 'PcrWellResultOvar'
        },
        initialize: function () {
            this.on('change', _.bind(function () {
                if (this.get('refPcrWell')) {
                    this.get('refPcrWell').trigger('change:tooltip');
                }
            }, this));
        },

        defaults: {
            'cts': [],
            'result': null,
            'comment': '',
            'codeErr': null,
            'commentErrAuto': '',
            'justificatory': [],
            'refValUser': null,
            'refValUser2': null,
            'valSt': 0,
            'valTStmp': {},
            'valTStmp2': {},
            'propC': '',
            'mbAna': null,
            'internalControl': false,
            'needValidation': false,
            'sequence': undefined,
            'sequenceDisplay': undefined,
            'sequenceDisplayGroup': undefined,
            'sequenceValidation': 0,
            'refAssayResult': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'curves',
            relatedModel: 'Curve',
            collectionType: 'CurveCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refValUser',
            relatedModel: 'UserPid',
            includeInJSON: false
        }, {
            type: Backbone.HasOne,
            key: 'refValUser2',
            relatedModel: 'UserPid',
            includeInJSON: false
        }, {
            type: Backbone.HasOne,
            key: 'refAssayResult',
            relatedModel: 'AssayResultVersion',
            includeInJSON: false
        }, {
            type: Backbone.HasOne,
            key: 'mbAna',
            relatedModel: 'MbAna',
            includeInJSON: false
        }, {
            type: Backbone.HasOne,
            key: 'result',
            relatedModel: 'MbAnaRes',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getImageUid: function () {
        },
        getPDF: function () {
            return Settings.getDocUrl('PcrWellResultJson.pdf');
        },

        confirmResult: function (justificatory) {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('refPcrWell').get('secId') + '/confirm/' + this.get('refAssayResult').get('id')),
                defer = $.Deferred();
            $.ajax({
                url: url,
                type: 'PUT',
                data: justificatory,
                contentType: 'application/json',
                success: _.bind(function () {
                    // this.get('refPcrWell').get('refPcrRun').fetch().done(function () {
                    // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                    // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                    this.get('refPcrWell').fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        negativeResult: function (justificatory) {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('refPcrWell').get('secId') + '/negative/' + this.get('refAssayResult').get('id')),
                defer = $.Deferred();
            $.ajax({
                url: url,
                type: 'PUT',
                data: justificatory,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrWell').get('refPcrRun').fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getResultIcon: function (displayCt) {
            var resFormatted = (this.get('cts') && Array.isArray(this.get('cts'))) ? this.get('cts').join(';') : (this.get('cts') ? this.get('cts') : '');
            var quantificationFormatted = (this.get('quantifications') && Array.isArray(this.get('quantifications'))) ? this.get('quantifications')[0] : (this.get('quantifications') ? this.get('quantifications') : 0);
            var iconResultView = new WellResultIconView({
                model: this,
                displayCt: displayCt
            });
            iconResultView.render();

            var errMessComToDisplayList = '';
            var commentsList = [];

            if (this.get('codeErr') && this.get('codeErr') !== '') {
                commentsList.push(this.get('codeErr'));
            }
            if (this.get('justificatory') && this.get('justificatory') !== '') {
                commentsList.push(this.get('justificatory'));
            }
            if (this.get('comment') && this.get('comment') !== '') {
                commentsList.push(this.get('comment'));
            }

            if (commentsList.length > 0) {
                errMessComToDisplayList = commentsList.join(' - ');
            }

            return {
                targetCode: this.get('mbAna').get('code'),
                targetName: this.get('mbAna').get('name'),
                resCode: this.get('result') ? this.get('result').get('code') : null,
                resColor: this.get('result') ? this.get('result').get('color') : 0,
                valSt: this.get('valSt'),
                cts: resFormatted,
                quantifications: quantificationFormatted,
                propC: this.get('propC'),
                codeErr: this.get('codeErr'),
                commentErrAuto: this.get('commentErrAuto'),
                comment: this.get('comment'),
                justificatory: this.get('justificatory'),
                iconView: iconResultView,
                iconHtml: iconResultView.$el.html(),
                user: this.get('refValUser'),
                user2: this.get('refValUser2'),
                errMessComToDisplayList: errMessComToDisplayList,
                isOVAR: this.get('refAssayResult').get('type') === 'CALCULATED',
                color: this.get('result') ? this.get('result').get('color') : null
            };
        },

        getPropCFormated: function () {
            return JSON.stringify(this.get('propC'))
                .replaceAll('{', '')
                .replaceAll('}', '')
                .replaceAll('"', '')
                .replaceAll(',', '; ')
                .replaceAll(':', ': ');
        },
        isReadOnly: function () {
            return this.get('refPcrWell').isReadOnly(true) || this.get('refPcrWell').get('repeatStatus') !== null || (this.get('valSt') >= 4 && this.get('valSt') !== 9) ||
                (this.get('refAssayResult').get('type') === 'CURVE' && this.get('refPcrWell').get('valWst') >= 4 && this.get('valSt') !== 9) ||
                (this.get('valSt') === 3.1 && !(Settings.get('currentUserModel').hasType('EXPVAL')));
        },
        downloadRawData: function () {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('refPcrWell').get('secId') + '/results/' + this.get('secId') + '/curve');
            window.open(url, '_blank');
        },
        downloadFluorData: function () {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('refPcrWell').get('secId') + '/results/' + this.get('secId') + '/curve/fluor');
            window.open(url, '_blank');
        },
        showFluorData: function () {
            return Settings.url('compuzz', 'v2/wells/pcr/' + this.get('refPcrWell').get('secId') + '/results/' + this.get('secId') + '/curve/fluor');
        }
    });

    app.ns.PcrWellResultCollection = Backbone.Collection.extend({
        model: app.ns.PcrWellResult,
        comparator: function (model) {
            if (model.get('type') === 'OVAR') {
                return -1;
            }
            return model.get('sequence');
        }
    });

    module.exports = {
        getCollection: function (items) {
            return new app.ns.PcrWellResultCollection(items);
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.PcrWellResult.findOrCreate(modelJson);
        }
    };

    return app.ns.PcrWellResult;
});

