define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResults',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValues'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.StandardCurveAssayConfigurationResult = Backbone.RelationalModel.extend({
        defaults: {
            'values': [],
            assayConfigurationResult: null,
            concentration: null,
            sampleId: null,
            ct: null,
            commentary: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'values',
            relatedModel: 'StandardCurveAssayConfigurationResultValue',
            collectionType: 'StandardCurveAssayConfigurationResultValueCollection'
        }],
        getImageUid: function () {
        },

        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.StandardCurveAssayConfigurationResultCollection = Backbone.Collection.extend({
        model: app.ns.StandardCurveAssayConfigurationResult
    });

    module.exports = _.defaults({
        getName: function () {
            return 'standardcurveassayconfigurationresult';
        },
        getDynamicJson: function () {
            return 'StandardCurveAssayConfigurationResultJson';
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'assayConfigurationResult',
                    'param': {'type': 'ASSAYCONFIGURATIONRESULT_FROMASSAY', 'display': {'class': 'col-w-51 p-l-5'}}
                },
                {
                    'field': 'concentration',
                    'param': {'type': 'NUMBER', 'display': {'class': 'col-w-10 p-l-5 m-r-20'}}
                },
                {
                    'field': 'sampleId',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 p-l-5'}}
                },
                {
                    'field': 'concentrationSample',
                    'param': {'type': 'NUMBER', 'display': {'class': 'col-w-10 p-l-5'}}
                },
                {
                    'field': 'show',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showResults,
                        'display': {'class': 'col-w-5 p-r-2 p-l-2', 'mdi': 'mdi-eye'}
                    }
                }
            ];
        },
        showResults: function (param) {
            require(['standardCurveController'], function (Controller) {
                Controller.showResults(param);
            });
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.StandardCurveAssayConfigurationResult.findOrCreate(modelJson);
        }
    }, Dynamic);
});

