/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowController',[
    'module',
    'jquery',
    'underscore',
    'app',
    'settings',
    'contentShowView',
    'entities/caccounts/userpids',
    'entities/caccounts/setting',
    'entities/session'
], function (
    module,
    $,
    _,
    App,
    Settings,
    View,
    Userpids,
    SettingEntity
) {
    'use strict';

    module.exports = {
        showContent: function () {
            var defer = $.Deferred(),
                view,
                currentUserGlobal = Settings.get('currentUserGlobal');

            if (App.regions &&
                _.isUndefined(App.regions.getRegion('main').currentView) ||
                (App.regions.getRegion('main').currentView &&
                    _.isUndefined(App.regions.getRegion('main').currentView.getRegion)) ||
                _.isUndefined(App.regions.getRegion('main').currentView.getRegion('content')) ||
                this.switchCaccount || App.getCurrentRoute() === '') {

                this.switchCaccount = false;
                var cAccount = Settings.get('currentCAccount');
                var reloadData = $.Deferred();
                reloadData.done(_.bind(function () {
                    currentUserGlobal = Settings.get('currentUserGlobal');
                    view = new View();

                    var defers = [];
                    defers.push(Userpids.getByGlobal(currentUserGlobal.secId));
                    defers.push(SettingEntity.getAllSettingFull());
                    $.when.all(defers).done(_.bind(function (response) {
                        Settings.set('currentUserModel', response[0]);
                        App.regions.getRegion('main').show(view);
                        App.trigger('header:show');
                        App.trigger('sidebar:show', response[1]);
                        App.off('switchCaccount');
                        App.on('switchCaccount', _.bind(this.onSwitchCAccount, this));
                        defer.resolve();
                    }, this));
                }, this));

                if (cAccount && cAccount.secId && currentUserGlobal) {
                    reloadData.resolve();
                } else {
                    require(['entities/global/token'], function (TokenEntities) {
                        TokenEntities.findBy().done(function (model) {
                            Settings.set('currentUserGlobal', model.userpid);
                            Settings.set('currentCAccount', model.caccount);
                            reloadData.resolve();
                        }).fail(function () {
                            Settings.set('lastUrlPageCookie', App.getCurrentRoute());
                            App.clearAuthCookieValues();
                            App.navigate('login', {trigger: true});
                        });
                    });
                }
            } else {
                App.regions.getRegion('main').currentView.triggerMethod('before:render');
                defer.resolve();
            }
            return defer.promise();
        },

        onSwitchCAccount: function (caccount) {
            var postCaccount = App.request('caccount:post-caccount', caccount.secId);

            postCaccount.done(_.bind(function (data) {
                Settings.clearMemory();
                Settings.set('tokenId', data.tokenId);
                Settings.set('currentCAccount', caccount);
                this.switchCaccount = true;
                App.navigate('', {trigger: true});
            }, this));
        }
    };
});

