define('entities/global/caccounts',[
    'app',
    'jquery',
    'underscore',
    'backbone',
    'settings',
    'module',
    'dynamic',
    'bootbox',
    'backboneRelational'
], function (
    App,
    $,
    _,
    Backbone,
    Settings,
    module,
    Dynamic,
    Bootbox
) {
    'use strict';

    /**
     * Model
     */
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.CAccount = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/caccounts/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            vatNumber: undefined,
            name: undefined,
            zip: undefined,
            city: undefined,
            address: undefined,
            code: undefined,
            phone: undefined,
            contactEmail: undefined,
            forceInvoiceGeneration: false,
            country: undefined,
            logo: null
        },

        url: function () {
            return Settings.url('compuzz', 'v2/caccounts/' + this.get('secId'));
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/caccounts' + (this.get('secId') ? '/' + this.get('secId') : ''));
        },

        postCAccount: function () {
            var url = Settings.url('compuzz', 'v2/sessions.json/token/byuser/' +
                this.get('secId'));

            return $.ajax({
                type: 'POST',
                url: url
            });
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        update: function () {
            this.url = this.postUrl();
            return this.save();
        },

        idAttribute: 'secId',
        getEntity: function () {
            return module.exports;
        },
        updateLogo: function (fileInfo) {
            var url = Settings.url('compuzz', 'v2/caccounts/' + this.get('secId') + '/logo'),
                defer = $.Deferred();

            this.trigger('request');

            var formdata = new FormData();
            formdata.append('file', fileInfo);
            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (resp) {
                    defer.reject(resp);
                }
            });
            return defer.promise();
        },
        importDatabase: function (fileInfo) {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('compuzz', 'v2/caccounts/' + this.get('secId') + '/database/import'),
                defer = $.Deferred();

            var formdata = new FormData();
            formdata.append('file', fileInfo);
            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function () {
                    defer.resolve();
                },
                error: function (resp) {
                    defer.reject(resp);
                },
                complete: function () {
                    loader.hide();
                }
            });
            return defer.promise();
        },
        exportDatabase: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('compuzz', 'v2/caccounts/' + this.get('secId') + '/database/export');
            var xhr = new XMLHttpRequest();

            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';

            xhr.onreadystatechange = function () {
                loader.hide();
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');
                        // Expression régulière pour extraire le nom du fichier
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDispositionHeader);

                        // Récupérer le nom du fichier depuis les correspondances
                        var fileName = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : 'exported_database.zip';

                        var blob = new Blob([xhr.response], {type: 'application/zip'});
                        var url = window.URL.createObjectURL(blob);

                        var a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = fileName;

                        document.body.appendChild(a);
                        a.click();

                        window.URL.revokeObjectURL(url);

                        alert('Your file has downloaded!');
                    } else {
                        alert('Error downloading file. Status: ' + xhr.status);
                    }
                }
            };

            xhr.send();
        },
        deleteDatabase: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('compuzz', 'v2/caccounts/' + this.get('secId') + '/database'),
                defer = $.Deferred();
            $.ajax({
                type: 'DELETE',
                url: url,
                success: function () {
                    defer.resolve();
                },
                error: function (resp) {
                    defer.reject(resp);
                },
                complete: function () {
                    loader.hide();
                }
            });
            return defer.promise();
        },
        createDatabase: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('compuzz', 'v2/caccounts/' + this.get('secId') + '/database/create'),
                defer = $.Deferred();
            $.ajax({
                type: 'GET',
                url: url,
                success: function () {
                    defer.resolve();
                },
                error: function (resp) {
                    defer.reject(resp);
                },
                complete: function () {
                    loader.hide();
                }
            });
            return defer.promise;
        }
    });

    /**
     * Collection
     */
    app.ns.CAccountCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/caccounts');
        },
        model: app.ns.CAccount,
        comparator: 'code'
    });

    /**
     * REST API
     */
    var API = {

        getCAccountCollection: function () {
            var cAccounts = new app.ns.CAccountCollection({});

            cAccounts.url = Settings.url('compuzz', 'v2/caccounts');

            return cAccounts;
        },

        getCAccountCollectionUrl: function () {
            return Settings.url('compuzz', 'v2/caccounts');
        },

        getCAccountAllCollectionUrl: function () {
            return Settings.url('compuzz', 'v2/caccounts/all');
        },

        getRowCountUrl: function () {
            return 'v2/caccounts/rowCount';
        },

        getAllRowCountUrl: function () {
            return 'v2/caccounts/all/rowCount';
        },


        postCAccount: function (secId) {
            var url = Settings.url('compuzz', 'v2/sessions.json/token/byuser/' + secId),
                defer = $.Deferred();

            $.ajax({
                type: 'POST',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        loginCAccount: function (data) {
            var params = {
                    userpidSecId: data.userId,
                    cAccountSecId: data.cAccountId
                },
                url = Settings.url('compuzz', 'v2/navigations/autologin', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        getCAccountWithDuplicateIP: function (secId) {
            var defer = $.Deferred();

            $.ajax({
                url: Settings.url('compuzz', 'v2/caccounts/' + secId + '/duplicate/ip'),
                success: function (resp) {
                    _.each(resp, function (cAccount) {
                        cAccount.logo = cAccount.logo ? Settings.url('file', cAccount.logo) : Settings.get('notFoundImage');
                    });
                    defer.resolve(resp);
                }
            });

            return defer.promise();
        }

    };

    /**
     * Request communications
     */

    App.reqres.setHandler('caccount:row-count-url', function () {
        return API.getRowCountUrl();
    });

    App.reqres.setHandler('caccount:all:row-count-url', function () {
        return API.getAllRowCountUrl();
    });

    App.reqres.setHandler('caccount:get-collection', function () {
        return API.getCAccountCollection();
    });

    App.reqres.setHandler('caccount:get-collection-url', function () {
        return API.getCAccountCollectionUrl();
    });

    App.reqres.setHandler('caccount:all:get-collection-url', function () {
        return API.getCAccountAllCollectionUrl();
    });

    App.reqres.setHandler('caccount:post-caccount', function (secId) {
        return API.postCAccount(secId);
    });

    App.reqres.setHandler('caccount:new-login', function (data) {
        return API.loginCAccount(data);
    });

    App.reqres.setHandler('caccount:duplicate:ip', function (secId) {
        return API.getCAccountWithDuplicateIP(secId);
    });

    module.exports = _.defaults({
        getName: function () {
            return 'caccount';
        },
        getUrl: function () {
            return 'v2/caccounts';
        },
        getDynamicJson: function () {
            return 'CAccountJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.deletable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.delete'),
                name: 'delete',
                classes: 'dynamic-link',
                formatter: viewDynamic.deleteIconFormatter,
                width: 10,
                search: false,
                onClick: _.bind(this.deleteCaccountClick, this)
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.secId'),
                name: 'secId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('caccount.databaseStatus'),
                name: 'databaseMigrated',
                classes: 'dynamic-link',
                formatter: _.bind(this.databaseStatus, this),
                search: false,
                width: 20
            }, {
                label: _.i18n('caccount.databaseSizeInBytes'),
                name: 'databaseSizeInBytes',
                classes: 'dynamic-link',
                formatter: _.bind(this.databaseSizeInBytes, this),
                search: false,
                width: 20
            }, {
                label: _.i18n('caccount.createDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.createDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.createDatabaseClick, this)
            }, {
                label: _.i18n('caccount.exportDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.exportDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.exportDatabaseClick, this)
            }, {
                label: _.i18n('caccount.importDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.importDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.importDatabaseClick, this)
            }, {
                label: _.i18n('caccount.deleteDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.deleteDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.deleteDatabaseClick, this)
            });
            return columns;
        },

        databaseStatus: function (cellValue, options) {
            if (cellValue) {
                cellValue = '<span class="mdi mdi-check"></span>';
            } else {
                cellValue = '<span class="mdi mdi-close-circle"></span>';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        createDatabase: function (cellValue, options) {
            if (!cellValue) {
                cellValue = '<span class="mdi mdi-database-plus clickable"></span>';
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        exportDatabase: function (cellValue, options) {
            if (cellValue) {
                cellValue = '<span class="mdi mdi-database-export clickable"></span>';
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        importDatabase: function (cellValue, options) {
            cellValue = '<span class="mdi mdi-database-import clickable"></span>';

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        deleteDatabase: function (cellValue, options) {
            if (cellValue) {
                cellValue = '<span class="mdi mdi-database-remove clickable"></span>';
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        databaseSizeInBytes: function (cellValue, options) {
            if (cellValue) {
                cellValue = this._formatBytes(cellValue);
            } else {
                cellValue = 'N/A';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        _formatBytes: function (bytes) {
            if (bytes === 0) return '0 Bytes';

            var k = 1024;
            var dm = 2;

            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            var i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },

        createDatabaseClick: function (rowId) {
            var model = this.getModel({secId: rowId});
            model.createDatabase();
        },

        exportDatabaseClick: function (rowId) {
            var model = this.getModel({secId: rowId});
            model.exportDatabase();
        },
        deleteDatabaseClick: function (rowId) {
            Bootbox.prompt({
                title: 'Write DELETE to confirm',
                centerVertical: true,
                callback: _.bind(function (result) {
                    if (result === 'DELETE') {
                        var model = this.getModel({secId: rowId});
                        model.deleteDatabase();
                    } else {
                        Bootbox.alert('!!! Database NOT deleted !!!');
                    }
                }, this)
            });
        },
        deleteCaccountClick: function (rowId) {
            Bootbox.prompt({
                title: 'Write DELETE to confirm',
                centerVertical: true,
                callback: _.bind(function (result) {
                    if (result === 'DELETE') {
                        this.deleteEntity(rowId);
                    } else {
                        Bootbox.alert('!!! CAccount NOT deleted !!!');
                    }
                }, this)
            });
        },
        importDatabaseClick: function (rowId) {

            // Créer dynamiquement un input de type fichier
            var fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.style.display = 'none';
            fileInput.accept = '.zip';

            // Ajouter le champ de fichier à votre body
            document.body.appendChild(fileInput);

            // Attacher un gestionnaire d'événements pour le changement de fichier sélectionné
            fileInput.addEventListener('change', function (event) {
                var selectedFile = event.target.files[0];

                // Supprimer l'élément input après avoir traité le fichier
                document.body.removeChild(fileInput);

                // Vérifier si un fichier a été sélectionné
                if (selectedFile) {
                    var model = this.getModel({secId: rowId});
                    model.importDatabase(selectedFile);
                }
            }.bind(this)); // Assurez-vous que "this" fait référence à votre objet actuel

            // Simuler un clic sur le champ de fichier
            fileInput.click();

        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.CAccount.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCAccountCount: function () {
            var defer = $.Deferred();

            $.ajax({
                url: Settings.url('compuzz', 'v2/sessions.json/token/byuser/caccounts/rowcount'),
                success: function (resp) {
                    defer.resolve(resp);
                }
            });

            return defer.promise();
        },

        getLanguages: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('compuzz', 'v2/languages'),
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        getCountries: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/countries');

            $.ajax({
                url: url,
                success: function (data) {
                    defer.resolve(new Backbone.Collection(data));
                }
            });

            return defer.promise();
        },
        getCAccountModel: function (cAccountId) {
            return this.getModel({'secId': cAccountId});
        },
        getCAccountEntities: function () {
            var cAccounts = new app.ns.CAccountCollection({}),
                defer = $.Deferred();

            cAccounts.url = Settings.url('compuzz', 'v2/caccounts/logged');
            cAccounts.fetch({
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        findBySecId: function (secId) {
            var url = Settings.url('compuzz', 'v2/caccounts/' + secId),
                defer = $.Deferred();
            if (!secId) {
                defer.resolve(app.ns.CAccount.findOrCreate({}));
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function (data) {
                        defer.resolve(app.ns.CAccount.findOrCreate(data));
                    }
                });
            }

            return defer.promise();
        }
    }, Dynamic);
});
