
define('template!forgotViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="well login-form-container">\n    <form class="form-horizontal">\n      <fieldset>\n        <div class="top-login-part text-center">\n          <a class="login-view-link">\n            <div class="avatar-view-link-img"></div>\n          </a>\n          <h2>' +
((__t = ( _.i18n("forgotPassword.title"))) == null ? '' : __t) +
'</h2>\n        </div>\n        <div class="form-group email-form-group">\n          <div class="col-lg-12">\n            <input type="email" class="form-control js-request-email floating-label"  placeholder="' +
((__t = ( _.i18n("common.email") )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="form-group">\n          <div class="col-lg-12 login-view-help">\n            <a class="btn btn-primary btn-lg btn-block js-send-request">' +
((__t = ( _.i18n("forgotPassword.sendRequest") )) == null ? '' : __t) +
'</a>\n            <a href="#login">' +
((__t = ( _.i18n("forgotpassword.navigate.login") )) == null ? '' : __t) +
'</a>\n          </div>\n        </div>        \n        <div class="form-group login-footer">\n          <div class="col-lg-12 text-center">\n            <p>Mobiolink</p>\n            <p>&#169; 2015</p>\n          </div>\n        </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

