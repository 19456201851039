define('wellPopupDisplayGraphView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!wellPopupDisplayGraphView',
    'savingBehavior',
    'bootbox',
    'underscore',
    'app',
    'autocompleteView',
    'settings',
    'jquery',
    'wellsPopupGraphView',
    'entities/caccounts/runats'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    App,
    AutocompleteView,
    Settings,
    $,
    WellsGraphView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        models: [],

        ui: {
            resRadio: '.res-radio',
            warnErrRadio: '.warnerr-radio',
            cbxValidation: '.cbx-validation',
            ccRadio: '.cc-radio',
            // cvRadio: '.cv-radio',
            lblAssay: '.lbl-assay',
            lblTarget: '.lbl-target',
            wellResCbx: '.js-select-wellres',
            loader: '.empty-page-loader'
        },

        events: {
            'change @ui.resRadio': 'onChangeCbx',
            'change @ui.warnErrRadio': 'onChangeCbx',
            'change @ui.cbxValidation': 'onChangeCbx',
            'change @ui.ccRadio': 'onCCRadioChange',
            // 'change @ui.cvRadio': 'onCVRadioChange',
            'change @ui.wellResCbx': 'onChangeCbx',
            'change .chart-legend-change': 'onLegendChange',
            'change .rangeSmooth': 'onRangeSmoothChange'
        },

        regions: {
            runGraphRaw: '#run-graphRaw-region-graphPopup',
            runGraph: '#run-graph-region-graphPopup',
            runGraph1: '#run-graph1-region-graphPopup',
            runGraph2: '#run-graph2-region-graphPopup',
            runAmplGraphRaw: '#run-ampl-graphRaw-region-graphPopup',
            runAmplGraph: '#run-ampl-graph-region-graphPopup',
            runAmplGraph1: '#run-ampl-graph1-region-graphPopup',
            runAmplGraph2: '#run-ampl-graph2-region-graphPopup'
        },

        onLegendChange: function (event) {
            this.displayLegend = event.target.checked;
            this.renderGraphics(this.currentModels, true);
        },
        onRangeSmoothChange: function (event) {
            this.rangeSmooth = event.target.checked ? 1 : 0;
            this.renderGraphics(this.currentModels, true);
        },

        initialize: function (options) {
            this.models = options.models;
            this.currentModels = this.models;
            this.legend = true;
            this.withoutCC = false;
            this.fixedColor = true;
            this.displayLegend = false;
            this.rangeSmooth = 1;
        },

        serializeData: function () {
            var templateData = {};
            this.results = [];
            templateData.newItem = this.options.newItem;
            templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
            templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
            templateData.results = this.results;
            templateData.statuss = this.statuss;
            templateData.ctrls = this.ctrls;
            templateData.displayCC = this.model.get('refPcrWell').get('assayVersion').get('colorCompensation');
            templateData.runNameToDisplay = this.model.get('refPcrWell').get('refPcrRun').get('name');
            templateData.assayToDisplay = this.model.get('refPcrWell').get('refAssay').get('code');
            templateData.wellPosToDisplay = this.model.get('refPcrWell').get('pos');
            templateData.targetToDisplay = this.model.get('refAssayResult').get('code');
            templateData.rangeSmooth = this.rangeSmooth;
            templateData.iconHtml = this.model.getResultIcon(true).iconHtml;

            return templateData;
        },

        onShow: function () {
            $.material.init();
            $(this.ui.ccRadio[0]).prop('checked', true);
            // $(this.ui.cvRadio[0]).prop('checked', true);
            $(this.ui.resRadio[0]).prop('checked', true);
            $(this.ui.warnErrRadio[0]).prop('checked', true);
            this.ui.cbxValidation.prop('checked', true);

            $('#main-region').addClass('hidden-print');
            this.renderGraphics(this.collection);
            this.triggerMethod('enable:cancel:confirm');
        },

        onCCRadioChange: function () {
            if (this.ui.ccRadio) {
                _.each(this.ui.ccRadio, _.bind(function (radio) {
                    if (radio.checked) {
                        this.withoutCC = radio.value === 'no';
                    }
                }, this));
                this.renderGraphics(this.collection, true);
            }
        },

        onChangeCbx: function (keepSlider) {
            if (!keepSlider || typeof keepSlider === 'object') {
                this.minValY = null;
                this.maxValY = null;
                this.minVal = null;
                this.maxVal = null;
            }

            this.ui.loader.hide();
            var resultToDisplay = [], validation = [], models = [];
            var addAllRes = false, addOnlyWarnErr = false, wellRes, errCode;
            _.each(this.ui.resRadio, _.bind(function (radio) {
                if (radio.checked) {
                    if (radio.value === 'all') {
                        addAllRes = true;
                    } else {
                        resultToDisplay.push(radio.value);
                    }
                }
            }, this));

            _.each(this.ui.warnErrRadio, _.bind(function (radio) {
                if (radio.checked) {
                    if (radio.value === 'all') {
                        errCode = null;
                    } else {
                        addOnlyWarnErr = true;
                    }
                }
            }, this));

            if (this.ui.wellResCbx.val() !== 'all') {
                wellRes = this.ui.wellResCbx.val();
            }

            _.each(this.ui.cbxValidation, _.bind(function (cbx) {
                cbx = $(cbx);
                var val = cbx.data('value');
                if (cbx.is(':checked')) {
                    validation.push(val);
                }
            }, this));

            this.models.each(_.bind(function (model) {
                if (addAllRes &&
                    !addOnlyWarnErr &&
                    validation.includes('all') &&
                    (!wellRes || model.get('wellRes') === wellRes) &&
                    !errCode &&
                    !this.minVal) {
                    models.push(model);
                }
            }, this));

            this.currentModels = models;
            this.trigger('selection:change', models);
            // todo models is array and renderGraphics uses a collection
            this.renderGraphics(models);
        },


        renderGraphics: function (models) {
            var region;
            if (models && models.size() > 0) {
                this.ui.lblAssay.text(this.model.get('refPcrWell').get('refAssay').code);
                this.ui.lblTarget.text(this.options.targetCode);
            } else {
                this.ui.lblAssay.text('');
                this.ui.lblTarget.text('');
            }

            if (this.graphToDisplay.includes('M')) {
                this.graphRawView = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graphRawView.models = models;
                this.graphRawView.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.graphRawView.slopeEnable = true;
                this.graphRawView.brutEnable = false;
                this.graphRawView.legend = this.legend;
                this.graphRawView.withoutCC = this.withoutCC;
                this.graphRawView.raw = true;
                this.graphRawView.fixedColor = this.fixedColor;
                this.graphRawView.highlight = this.model.get('refPcrWell');
                this.graphRawView.targetColor = this.targetColor;
                this.graphRawView.onCurveClick = this.onCurveClick;

                region = this.getRegion('runGraphRaw');
                if (region) {
                    region.show(this.graphRawView);
                }

                this.graphView = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graphView.models = models;
                this.graphView.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.graphView.slopeEnable = true;
                this.graphView.brutEnable = false;
                this.graphView.legend = this.legend;
                this.graphView.withoutCC = this.withoutCC;
                this.graphRawView.raw = false;
                this.graphView.fixedColor = this.fixedColor;
                this.graphView.highlight = this.model.get('refPcrWell');
                this.graphView.targetColor = this.targetColor;
                this.graphView.onCurveClick = this.onCurveClick;

                region = this.getRegion('runGraph');
                if (region) {
                    region.show(this.graphView);
                }

                this.graph1View = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graph1View.models = models;
                this.graph1View.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.graph1View.slopeEnable = true;
                this.graph1View.brutEnable = false;
                this.graph1View.deriv1Enable = true;
                this.graph1View.legend = this.legend;
                this.graph1View.withoutCC = this.withoutCC;
                this.graphRawView.raw = false;
                this.graph1View.fixedColor = this.fixedColor;
                this.graph1View.highlight = this.model.get('refPcrWell');
                this.graph1View.targetColor = this.targetColor;
                this.graph1View.onCurveClick = this.onCurveClick;


                region = this.getRegion('runGraph1');
                if (region) {
                    region.show(this.graph1View);
                }

                this.graph2View = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graph2View.models = models;
                this.graph2View.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.graph2View.slopeEnable = false;
                this.graph2View.brutEnable = true;
                this.graph2View.deriv2Enable = true;
                this.graph2View.legend = this.legend;
                this.graph2View.withoutCC = this.withoutCC;
                this.graph2View.raw = false;
                this.graph2View.fixedColor = this.fixedColor;
                this.graph2View.highlight = this.model.get('refPcrWell');
                this.graph2View.targetColor = this.targetColor;
                this.graph2View.onCurveClick = this.onCurveClick;

                region = this.getRegion('runGraph2');
                if (region) {
                    region.show(this.graph2View);
                }
            }

            if (this.graphToDisplay.includes('A')) {
                this.amplGraphRawView = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraphRawView.models = models;
                this.amplGraphRawView.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.amplGraphRawView.slopeEnable = false;
                this.amplGraphRawView.brutEnable = true;
                this.amplGraphRawView.amplGraph = true;
                this.amplGraphRawView.legend = this.legend;
                this.amplGraphRawView.withoutCC = this.withoutCC;
                this.amplGraphRawView.raw = true;
                this.amplGraphRawView.fixedColor = this.fixedColor;
                this.amplGraphRawView.highlight = this.model.get('refPcrWell');
                this.amplGraphRawView.targetColor = this.targetColor;
                this.amplGraphRawView.minXVal = this.minVal;
                this.amplGraphRawView.maxXVal = this.maxVal;
                this.amplGraphRawView.minYVal = this.minValY;
                this.amplGraphRawView.maxYVal = this.maxValY;
                this.amplGraphRawView.onCurveClick = this.onCurveClick;

                region = this.getRegion('runAmplGraphRaw');
                if (region) {
                    region.show(this.amplGraphRawView);
                }

                this.amplGraphView = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraphView.models = models;
                this.amplGraphView.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.amplGraphView.slopeEnable = false;
                this.amplGraphView.brutEnable = true;
                this.amplGraphView.amplGraph = true;
                this.amplGraphView.legend = this.legend;
                this.amplGraphView.withoutCC = this.withoutCC;
                this.amplGraphRawView.raw = false;
                this.amplGraphView.fixedColor = this.fixedColor;
                this.amplGraphView.highlight = this.model.get('refPcrWell');
                this.amplGraphView.targetColor = this.targetColor;
                this.amplGraphView.minXVal = this.minVal;
                this.amplGraphView.maxXVal = this.maxVal;
                this.amplGraphView.minYVal = this.minValY;
                this.amplGraphView.maxYVal = this.maxValY;
                this.amplGraphView.onCurveClick = this.onCurveClick;

                if (this.displaySlider) {
                    this.amplGraphView.filterValuesX = _.bind(function (min, max) {
                        this.minVal = min;
                        this.maxVal = max;
                        this.onChangeCbx(min, max);
                    }, this);
                    this.amplGraphView.filterValuesY = _.bind(function (min, max) {
                        this.minValY = min;
                        this.maxValY = max;
                        this.onChangeCby(min, max);
                    }, this);
                }

                if (this.runAt) {
                    this.amplGraphView.baseline = this.runAt.baseline;
                    this.amplGraphView.threshold = this.runAt.threshold;
                }

                region = this.getRegion('runAmplGraph');
                if (region) {
                    region.show(this.amplGraphView);
                }

                this.amplGraph1View = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraph1View.models = models;
                this.amplGraph1View.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.amplGraph1View.slopeEnable = true;
                this.amplGraph1View.brutEnable = false;
                this.amplGraph1View.deriv1Enable = true;
                this.amplGraph1View.amplGraph = true;
                this.amplGraph1View.legend = this.legend;
                this.amplGraph1View.withoutCC = this.withoutCC;
                this.amplGraph1View.raw = false;
                this.amplGraph1View.fixedColor = this.fixedColor;
                this.amplGraph1View.highlight = this.model.get('refPcrWell');
                this.amplGraph1View.targetColor = this.targetColor;
                this.amplGraph1View.onCurveClick = this.onCurveClick;

                region = this.getRegion('runAmplGraph1');
                if (region) {
                    region.show(this.amplGraph1View);
                }

                this.amplGraph2View = new WellsGraphView({
                    type: 'line', displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraph2View.models = models;
                this.amplGraph2View.modelRun = this.model.get('refPcrWell').get('refPcrRun');
                this.amplGraph2View.slopeEnable = true;
                this.amplGraph2View.brutEnable = false;
                this.amplGraph2View.deriv2Enable = true;
                this.amplGraph2View.amplGraph = true;
                this.amplGraph2View.legend = this.legend;
                this.amplGraph2View.withoutCC = this.withoutCC;
                this.amplGraphRawView.raw = false;
                this.amplGraph2View.fixedColor = this.fixedColor;
                this.amplGraph2View.highlight = this.model.get('refPcrWell');
                this.amplGraph2View.targetColor = this.targetColor;
                this.amplGraph2View.onCurveClick = this.onCurveClick;

                region = this.getRegion('runAmplGraph2');
                if (region) {
                    region.show(this.amplGraph2View);
                }
            }
        },

        onSave: function () {

        },

        // added todo test
        onCurveClick: function (well) {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails(well, well.get('pos'), null);
            }, this));
        },

        hide: function () {
            $('#main-region').removeClass('hidden-print');
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
});

