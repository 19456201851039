define('entities/caccounts/pcrwells',[
    'app',
    'backbone',
    'settings',
    'colorUtils',
    'wellResultIconView',
    'jquery',
    'underscore',
    'module',
    'entities/caccounts/userCodelist',
    'backboneRelational',
    'entities/caccounts/pcrruns',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/samples',
    'entities/caccounts/assay',
    'entities/caccounts/assayVersion',
    'entities/caccounts/prepwells',
    'entities/caccounts/curves'
], function (
    App,
    Backbone,
    Settings,
    ColorUtils,
    WellResultIconView,
    $,
    _,
    module,
    UserCodeLists
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PcrWell = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'runName': '',
            'runSecId': '',
            'pos': '',
            'smpType': 'E',
            'sampleComment': '',
            'tags': undefined,
            'wellsPooled': undefined,
            'wellsPool': undefined,
            'refAssay': null,
            'assayVersion': null,
            'repeatStatus': null,
            'errorInd': '',
            'valWst': 0,
            'dilution': '',
            'exported': false,
            'results': [],
            'existOtherWellSameAssay': false,
            'existOtherWellOtherAssay': false,
            'curves': [],
            'smpId': null
        },
        initialize: function () {
            this.set('tags', []);
            this.set('wellsPooled', []);
            this.set('wellsPool', []);
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'PcrWellResult',
            collectionType: 'PcrWellResultCollection',
            reverseRelation: {
                key: 'refPcrWell'
            }
        }, {
            type: Backbone.HasMany,
            key: 'wellsPooled',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPool',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refPcrRun',
            relatedModel: 'PcrRun',
            reverseRelation: {
                includeInJSON: false
            }
        }, {
            type: Backbone.HasOne,
            key: 'refPrepWell',
            relatedModel: 'PrepWell'
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay'
        }, {
            type: Backbone.HasOne,
            key: 'assayVersion',
            relatedModel: 'AssayVersion'
        }, {
            type: Backbone.HasMany,
            key: 'curves',
            relatedModel: 'Curve',
            collectionType: 'CurveCollection',
            reverseRelation: {
                key: 'refPcrWell'
            }
        }, {
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample'
        }],

        getImageUid: function () {
        },

        getTargetsResultIcons: function (displayCt) {
            var targetsResultsIcons = [];
            this.get('results').each(_.bind(function (target) {
                targetsResultsIcons.push(target.getResultIcon(displayCt));
            }, this));

            return targetsResultsIcons;
        },

        getWellRes: function () {
            return _.first(this.getDisplayGroup());
        },

        getDisplayGroup: function () {
            return this.get('results').filter(function (result) {
                return result.get('refAssayResult').get('overAllResult');
            });
        },
        postUrl: function (runSecId) {
            return Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/wells');
        },

        save: function () {
            this.url = this.postUrl(this.get('runSecId'));
            return Backbone.Model.prototype.save.call(this);
        },

        saveResults: function (results) {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/results'),
                defer = $.Deferred();

            var body = {
                'tags': this.get('tags'),
                'pcrWellResultRequests': results
            };
            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveHeader: function (requestModel) {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(requestModel),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveSmpid: function () {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/sampleId'),
                defer = $.Deferred();

            var body = this.get('smpId').get('name');
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        /**
         * Validation 1 for a well
         * @returns {*|jQuery}
         */
        validation1: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/validation');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.get('refPcrRun').fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 2 for a well
         * @returns {*|jQuery}
         */
        validation2: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/validation2');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 12 for a well
         * @returns {*|jQuery}
         */
        validation12: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/validation12');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Cancel the validation process for the PCR well.
         *
         * @returns {Promise} A Promise that resolves when the validation is successfully canceled, and rejects with an error if there was an error canceling the validation.
         */
        cancelValidation: function () {
            var defer = $.Deferred();
            var url;
            if (parseInt(this.get('valWst'), 10) === 3) {
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/cancelValidation1');
            } else {
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/cancelValidation2');
            }


            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        /**
         * Validation Expert for a well
         * @returns {*|jQuery}
         */
        validationExpert: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/validationExpert');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation Expert for a well
         * @returns {*|jQuery}
         */
        requireExpertValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/requireExpertValidation');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCR: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/repeat/PT');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },


        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCRControl: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/repeat/PC');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtraction: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/repeat/XT', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionControl: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/repeat/XC', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatPoolToDissolve: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/repeat/DP');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/controlKitLot');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getWellControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/controlKitLot/wells');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(new app.ns.PcrWellCollection(response));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well
         * @returns {*|jQuery}
         */
        getPcrKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/kitLots');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PcrWellJson.pdf');
        },

        getErrorObject: function () {
            var errorObject = {
                error: false,
                warning: false,
                ok: false
            };

            switch (this.get('errorInd')) {
                case 'E':
                    errorObject.error = true;
                    break;
                case 'W':
                    errorObject.warning = true;
                    break;
            }

            if (!errorObject.warning && !errorObject.error && this.get('results').some({'codeErr': 'W'})) {
                errorObject.warning = true;
            }

            if (!errorObject.error && this.get('results').some({'codeErr': 'E'})) {
                errorObject.warning = false;
                errorObject.error = true;
            }

            if (!errorObject.error && !errorObject.warning) {
                errorObject.ok = true;
            }
            return errorObject;
        },

        findDissolveds: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/dissolved');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getGraphToDisplay: function () {
            if (this.get('results').isEmpty()) {
                return _.map(_.uniq(this.get('curves').pluck('type')), function (type) {
                    return type.substring(0, 1);
                });
            }
            var type = '';
            this.get('results').each(function (result) {
                if (result.get('curves').isEmpty()) {
                    return;
                }
                if (result.get('curves').any(function (curve) {
                    return curve.get('type') === 'AMP';
                })) {
                    if (!type.includes('A')) {
                        type += 'A';
                    }
                }
                if (result.get('curves').any(function (curve) {
                    return curve.get('type') === 'MELT';
                })) {
                    if (!type.includes('M')) {
                        type += 'M';
                    }
                }
            });
            return type;
        },

        isReadOnly: function (dontCheckResult) {
            return this.get('repeatStatus') !== null || (!dontCheckResult && this.get('results').any(function (result) {
                return result.isReadOnly();
            }));
        },

        canChangeSmpId: function () {
            return (this.get('refPrepWell') === undefined || this.get('refPrepWell') === null || !this.get('refPrepWell')) && (this.get('valWst') <= 5 || this.get('valWst') === 9);
        },

        hasAlreadyTargetValidated: function () {
            return this.get('results').any(function (result) {
                return result.get('valSt') >= 3 && result.get('valSt') !== 9;
            });
        },

        findWellResult: function (assayResult) {
            if (!this.get('refAssay')) {
                return null;
            }
            return _.first(this.get('results').filter(function (result) {
                return result.get('refAssayResult').get('id') === assayResult.id;
            }));
        },
        /**
         * Refresh well
         * @returns {*|jQuery}
         */
        refreshWell: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/refresh');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPcrRun').fetch().done(function () {
                        // The refPcrRun fetch trigger a model change that kill currentTab and runList in UI,
                        // so the run model is refreshed at 'wellDetailView' hide or next/previous navigation
                        // this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        getEntity: function () {
            return module.exports;
        },
        saveDetail: function () {
            var url = Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/detail'),
                defer = $.Deferred();

            var tags = this.get('tags');
            if (tags && (!Array.isArray(tags)) && tags !== '' && tags !== undefined) {
                tags = this.get('tags').split(/[,;\s]/);
            }

            var body = {
                tags: tags
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getDataForPcrKitLot: function () {
            return {
                url: Settings.url('compuzz', 'v2/wells/pcr/' + this.get('secId') + '/controlKitLot/wells/'),
                rowCountUrl: 'v2/wells/pcr/' + this.get('secId') + '/controlKitLot/wells/rowCount'
            };
        },
        getType: function () {
            var types = this.get('results').chain().map(function (model) {
                return model.get('type');
            }).uniq().value();
            if (_.any(types, function (type) {
                return type === 'MAGPIX';
            })) {
                return 'MAGPIX';
            }
            if (_.any(types, function (type) {
                return type === 'HL7';
            })) {
                return 'HL7';
            }
            return 'CURVE';
        }
    });

    app.ns.PcrWellCollection = Backbone.Collection.extend({
        url: function (runSecId) {
            if (runSecId) {
                return Settings.url('compuzz', 'v2/wells/pcr/from/' + runSecId);
            } else {
                return Settings.url('compuzz', 'v2/runs/pcr');
            }
        },
        model: app.ns.PcrWell,
        comparator: function (m1, m2) {
            if (!m1 || !m1.get('pos')) {
                return -1;
            }
            if (!m2 || !m2.get('pos')) {
                return 1;
            }

            if (m1.get('refPcrRun') !== m2.get('refPcrRun') && m1.get('refPcrRun') instanceof app.ns.PcrRun && m2.get('refPcrRun') instanceof app.ns.PcrRun) {
                if (m1.get('refPcrRun').get('period') && m2.get('refPcrRun').get('period')) {
                    return String(m1.get('refPcrRun').get('period')).localeCompare(String(m2.get('refPcrRun').get('period')));
                }
                if (m1.get('refPcrRun').get('created') && m2.get('refPcrRun').get('created')) {
                    return String(m1.get('refPcrRun').get('created')).localeCompare(String(m2.get('refPcrRun').get('created')));
                }
            }

            var labSetting = m1.get('refPcrRun') && m1.get('refPcrRun').get && m1.get('refPcrRun').get('settings') ? m1.get('refPcrRun').get('settings')['LAB-SETTINGS'] : 'C';
            if (labSetting === 'R') {
                return m1.get('pos').localeCompare(m2.get('pos'));
            }
            return (m1.get('pos').substring(1) + m1.get('pos').charCodeAt(0)).localeCompare(m2.get('pos').substring(1) + m2.get('pos').charCodeAt(0));
        },

        /**
         *
         * @param assayResult
         * @returns {[]|*|Array}
         */
        search: function (assayResult) {
            var models = _.filter(this.models, function (model) {
                if (!assayResult) {
                    return true;
                }
                return model.get('results').some(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('id') === assayResult.id;
                });
            });
            return new app.ns.PcrWellCollection(models);
        },

        /**
         * récupère une liste de model sur base d'une recherche
         * @param from
         * @param step
         * @param to
         * @returns {*}
         */
        filterByPos: function (from, step, to) {
            var fromRow = from.substring(0, 1).charCodeAt(0) - 65;
            var fromColumn = Number(from.substring(1, 3));
            var toRow = to.substring(0, 1).charCodeAt(0) - 65;
            var toColumn = Number(to.substring(1, 3));
            var models = _.filter(this.models, function (model) {
                var pos = model.get('pos');
                var posRow = pos.substring(0, 1).charCodeAt(0) - 65;
                var posColumn = Number(pos.substring(1, 3));
                return (fromRow % step === posRow % step && fromColumn % step === posColumn % step) && (posRow <= toRow && posColumn <= toColumn);
            });
            return new app.ns.PcrWellCollection(models);
        },

        getAssays: function () {
            var assaySecIds = this.chain().filter(function (model) {
                return model.get('refAssay') && !model.get('refAssay').isNew();
            }).map(function (model) {
                return model.get('refAssay');
            }).uniq(false, function (assay) {
                return assay && assay.get('secId');
            }).compact().map(function (model) {
                return model.get('secId');
            }).value();

            var assayVersions = this.first().get('refPcrRun').get('assayVersions').filter(function (assayVersion) {
                return assaySecIds.includes(assayVersion.get('assay').get('secId'));
            });
            assayVersions = _.map(assayVersions, function (assayVersion) {
                return assayVersion;
            });
            return assayVersions;
        }
    });

    var API = {
        getErrCodeFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['name']};
            // 'code' must be showed by _getAutocompleteOptions(valueProperty)
        },

        getWellResultCollectionUrl: function (runSecId) {
            return Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/results', {});
        },

        process: function (lst, pcrWellsFromPrep, defer) {
            if (lst.length !== 0) {
                var obj = lst.pop();
                obj.run.fetch().done(function () {
                    obj.pcrWell.results = obj.run.getResultForWell(App.request('well:prep:model', obj.pcrWell)); //todo replace getResultForWell
                    API.process(lst, pcrWellsFromPrep, defer);
                }, this);
            } else {
                defer.resolve(pcrWellsFromPrep);
            }
        },

        getPcrWellFromPrepWell: function (wellSecId) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/pcr/fromPrepWell/' + wellSecId);

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    var retVal = [];
                    _.each(resp, _.bind(function (item) {
                        retVal.push(app.ns.PcrWell.findOrCreate(item));
                    }, this));
                    defer.resolve(retVal);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    };

    var STATIC = {
        filterWells: function (wells, from, to, step, row, column, filters) {
            console.info('%c check "filterWells" static method in pcrwells', 'color: #00f8ff');
            if (from) {
                var rowFrom = from.substring(0, 1);
                var columnFrom = parseInt(from.substring(1), 10);
            }
            if (to) {
                var rowTo = to.substring(0, 1);
                var columnTo = parseInt(to.substring(1), 10);
            }
            wells = wells.filter(_.bind(function (well) {
                var pos = well.get('pos');
                var rowCurrent = pos.substring(0, 1);
                var columnCurrent = parseInt(pos.substring(1), 10);
                if (from) {
                    if ((rowCurrent < rowFrom) ||
                        (columnCurrent < columnFrom) ||
                        ((columnCurrent - columnFrom) % step !== 0) ||
                        ((rowCurrent.charCodeAt(0) - rowFrom.charCodeAt(0)) % step !== 0)) {
                        return false;
                    }
                }
                if (to) {
                    if ((columnCurrent > columnTo) ||
                        (rowCurrent > rowTo)) {
                        return false;
                    }
                }
                if ((row && rowCurrent !== row) || (column && columnCurrent !== column)) {
                    return false;
                }

                if (filters) {
                    if (!well.get('refAssay')) {
                        return false;
                    }
                    if (filters.assaySecId && well.get('refAssay').get('secId') !== filters.assaySecId) {
                        return false;
                    }
                }
                return true;
            }, this));
            console.log('filter result - ' + wells.length);
            var collection = new app.ns.PcrWellCollection(wells);
            collection.sort();
            return collection.models;
        }
    };

    App.reqres.setHandler('well:pcr:get-collection-result-url', function (runSecId) {
        return API.getWellResultCollectionUrl(runSecId);
    });

    App.reqres.setHandler('well:pcr:model', function (wellJSON) {
        if (wellJSON) {
            return app.ns.PcrWell.findOrCreate(wellJSON);
        }
        return new app.ns.PcrWell();
    });

    App.reqres.setHandler('well:pcr:models', function (wellsJSON) {
        return new app.ns.PcrWellCollection(wellsJSON);
    });

    App.reqres.setHandler('well:pcr:target:negative', function (runSecId, assaySecId, target, lstSecIds, comment) {
        return API.negativeWell(runSecId, assaySecId, target, lstSecIds, comment);
    });

    App.reqres.setHandler('well:pcr:filter', function (wells, from, to, step, row, column, filters, multiRes, wellRes) {
        return STATIC.filterWells(wells, from, to, step, row, parseInt(column, 10), filters, multiRes, wellRes);
    });

    App.reqres.setHandler('well:pcr:find-dissolveds', function (wellSecId) {
        return STATIC.findDissolveds(wellSecId);
    });

    App.reqres.setHandler('well:pcr:get-errCode-fields-to-display', function () {
        return API.getErrCodeFieldsToDisplay();
    });

    App.reqres.setHandler('well:pcr:getWell:fromPrepWell', function (wellSecId) {
        return API.getPcrWellFromPrepWell(wellSecId);
    });

    module.exports = {
        getCollection: function (items) {
            return new app.ns.PcrWellCollection(items);
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.PcrWell.findOrCreate(modelJson);
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            var configurations = [];
            configurations.push({
                fieldName: 'tags',
                values: UserCodeLists.findItemsByCode('TAG_RESULT'),
                codeListCode: 'TAG_RESULT'
            });
            var configuration = _.findWhere(configurations, {fieldName: fieldName});
            if (configuration) {
                configuration.values.done(function (items) {
                    defer.resolve(items);
                });
            } else {
                defer.reject('No found for ' + fieldName);
            }
            return defer.promise();
        },
        getName: function () {
            return 'PcrWell';
        }
    };
});

