
define('template!pcrWellResultCurveItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="noWrapHidden result-wellres"></div>\n\n<div class="noWrapHidden result-channel" style="border-bottom: solid; height: 24px; ' +
((__t = ( dyeColorToDisplay )) == null ? '' : __t) +
'">\n    <label data-html="true" data-toggle="tooltip">' +
((__t = ( dyeToDisplay )) == null ? '' : __t) +
'</label>\n</div>\n\n<div class="noWrapHidden result-target">\n    <label data-html="true" data-toggle="tooltip"\n           title="' +
((__t = ( mbAna )) == null ? '' : __t) +
'">' +
((__t = ( mbAna )) == null ? '' : __t) +
'\n    </label>\n</div>\n\n<div class="noWrapHidden result-edit">\n    ';
 if (!refPcrWellExported && !readOnly && result) { ;
__p += '\n    <div class="btn-edit-well-target-result mdi-content-create clickable"\n         name="well-editTarget"\n         style="font-size: 16px; top: 4px;"\n         title="' +
((__t = ( _.i18n('icon.edit.wellTarget') )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n</div>\n\n<div class="noWrapHidden result-result" style="display:flex; position: relative;">\n    ' +
((__t = ( iconHtml )) == null ? '' : __t) +
'\n    ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-num">\n    ' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( quantifications ? '(' + quantifications.toExponential(2) + ')' : '')) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-action">\n    ';
 if (showWellCompareGraph) { ;
__p += '\n    <span class="mdi mdi-chart-box clickable btn-graph-well"\n          title="' +
((__t = ( _.i18n('well.tooltip.graph') )) == null ? '' : __t) +
'"></span>\n    <span class=" mdi mdi-chart-bell-curve-cumulative js-target-propC-popup clickable"></span>\n    ';
 } ;
__p += '\n    ';
 if (codeErr && !refPcrWellExported && valSt < 5 && !readOnly && needValidation) { ;
__p += '\n    <span class="mdi mdi-checkbox-marked btn-validate-result clickable"\n          style="color: #2ab937;" title="' +
((__t = ( _.i18n('well.tooltip.validate') )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n    ';
 if (allowToNeg && !readOnly && needValidation) { ;
__p += '\n    <span class="btn-negative-result mdi mdi-minus-circle-outline clickable"\n          style="color: red;" title="' +
((__t = ( _.i18n('well.tooltip.negative') )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n    ';
 if (commentErrAuto && !codeErr) { ;
__p += '\n    <span class="mdi mdi-alert" title="' +
((__t = ( commentErrAuto )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n</div>\n\n<div class="noWrapHidden result-custom"></div>\n\n<div class="noWrapHidden result-comment" title="' +
((__t = ( comment )) == null ? '' : __t) +
'">\n    ' +
((__t = ( comment )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-error" title="' +
((__t = ( errorMessage )) == null ? '' : __t) +
'">\n    ' +
((__t = ( errorMessage )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-val1">\n    <label title="' +
((__t = ( userVal1Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal1Code )) == null ? '' : __t) +
'\n    </label>\n</div>\n\n<div class="noWrapHidden result-val2">\n    <label title="' +
((__t = ( userVal2Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal2Code )) == null ? '' : __t) +
'\n    </label>\n</div>\n';

}
return __p
};});

