define('wellDisplayGraphView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!wellDisplayGraphView',
    'savingBehavior',
    'bootbox',
    'underscore',
    'app',
    'autocompleteView',
    'settings',
    'jquery',
    'wellsGraphView',
    'createEditRunAtLayoutView',
    'targetsView',
    'entities/caccounts/runats'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    App,
    AutocompleteView,
    Settings,
    $,
    WellsGraphView,
    CreateEditRunAtLayoutView,
    TargetsView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,
        displayHeader: true,

        models: [],

        ui: {
            cbxValidation: '.cbx-validation',
            cvRadio: '.cv-radio',
            loader: '.empty-page-loader',
            targetResCheckBoxRegion: '.js-targetRes-checkBox-region',
            errWarnCheckBoxRegion: '.js-errWarn-checkBox-region',
            repeatCheckBoxRegion: '.js-repeat-checkBox-region',
            resultBtn: '.js-result-btn',
            warningBtn: '.js-warning-btn',
            repeatBtn: '.js-repeat-btn',
            tab: 'li.nav-tab',
            firstTab: '.first-tab',
            rawDataAmpl: '.rawData.tab-ampl',
            rawDataMelt: '.rawData.tab-melt',
            colorCompensationBtn: '.js-colorCompensation-btn',
            chartLegendBtn: '.js-chartLegend-btn',
            smoothingBtn: '.js-smoothing-btn',
            rawDataBtn: '.js-rawData-btn'
        },

        events: {
            'click .js-confirm': 'onConfirm',
            'click .btn-edit-runat': 'onClickBtnEditRunAt',
            'change @ui.cvRadio': 'onCVRadioChange',
            'click @ui.colorCompensationBtn': 'onCCchange',
            'click @ui.chartLegendBtn': 'onLegendChange',
            'click @ui.smoothingBtn': 'onRangeSmoothChange',
            'click @ui.rawDataBtn': 'onRawDataChange',
            'click .js-result-btn': 'onFilterClick',
            'click .js-warning-btn': 'onFilterClick',
            'click .js-repeat-btn': 'onFilterClick',
            'click .js-target-reset-filters': 'onResetFilter',
            'click .js-run-valView-action-nav-drop-btn': 'onRunActionNavDropBtnClick',
            'click .js-run-valView-action-nav-drop-cell-btn': 'onRunActionNavDropCellBtnClick',
            'mouseleave .run-valView-action-nav-container': 'onHideRunActionContainer',
            'click .valView-dilution-dropdown': 'onDilution',
            'click @ui.tab': 'onTabClick'
        },

        regions: {
            graph: '.js-graph',
            targetsView: '#targets-view'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            error: 'onSaveError',
            change: 'renderSafe'
        },

        fieldsToValidate: [],

        filtersList: [],

        className: 'popup-graphs',

        onBeforePrintTargetGraph: function () {
            $('*').removeClass('hidden-print');
            $('#main-region').removeClass('hidden-print');
            $('#sidebar-region').addClass('hidden-print');
            $('.slider-range,.slider-range-vert,.mdi-action-cached').addClass('hidden-print');
        },

        initialize: function (options) {
            this.currentAssayTarget = options.currentAssayTarget;
            this.assays = options.assays;
            this.assayTargets = options.assayTargets;
            this.legend = null;
            this.withCC = true;
            this.withLegend = options.displayLegend;
            this.raw = false;
            this.fixedColor = true;
            switch (options.currentAssayTarget.algoResult.type) {
                case 'MELT':
                case 'MUTV':
                    this.graphToDisplay = 'M';
                    break;
                case 'AMP':
                case 'CRI':
                default:
                    this.graphToDisplay = 'A';
                    break;
            }
            this.hideOkButton = options.hideOkButton;
            this.wellSelected = options.wellSelected;
            this.rangeSmooth = 1;
            if (options.currentGraph) {
                if (options.currentGraph.startsWith('melt') && this.graphToDisplay.indexOf('A') === 0) {
                    options.currentGraph = 'amplCurvesS';
                } else if (options.currentGraph.startsWith('ampl') && this.graphToDisplay.indexOf('M') === 0) {
                    options.currentGraph = 'meltCurves1S';
                }
            } else {
                if (this.graphToDisplay.indexOf('A') === 0) {
                    options.currentGraph = 'amplCurvesS';
                } else if (this.graphToDisplay.indexOf('M') === 0) {
                    options.currentGraph = 'meltCurves1S';
                }
            }
            this.currentGraph = options.currentGraph;
            this.rawData = false;
        },

        serializeData: function () {
            var templateData = {};

            templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
            templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
            templateData.statuss = this.statuss;
            templateData.ctrls = this.ctrls;
            templateData.hideOkButton = this.hideOkButton;
            templateData.hideNextPreviousButton = this.hideNextPreviousButton;
            templateData.displayHeader = this.displayHeader;
            templateData.displayCC = false;
            var assay = _.findWhere(this.assays, {secId: this.currentAssayTarget.assayVersion.secId});
            if (assay) {
                templateData.displayCC = !!assay.colorCompensation;
            }
            templateData.legendChecked = this.withLegend;
            templateData.rangeSmooth = this.rangeSmooth;
            templateData.currentGraph = this.currentGraph;
            templateData.rawData = this.rawData;
            templateData.withCC = this.withCC;
            return templateData;
        },

        onRender: function () {
            this.targetsView = new TargetsView({
                currentAssayTarget: this.currentAssayTarget,
                assays: this.assays,
                assayTargets: this.assayTargets
            });

            this.targetsView.listenTo(this.targetsView, 'well:target:click', _.bind(this.onTargetClick, this));
            this.getRegion('targetsView').show(this.targetsView);
            this.fillTargetResRegionBox();
            this.fillErrWarnRegionBox();
            this.fillRepeatRegionBox();
            this.displayGraph();
        },

        onShow: function () {
            $.material.init();
            $(this.ui.cvRadio[0]).prop('checked', true);
            this.ui.cbxValidation.prop('checked', true);
            $('#main-region').addClass('hidden-print');
            this.triggerMethod('enable:cancel:confirm');
        },

        renderSafe: function () {
            clearTimeout(this.timer);
            if (!this.isDestroyed) {
                this.timer = setTimeout(_.bind(this.render, this), 100);
            }
        },

        onDilution: function (e) {
            e.preventDefault();
            e.stopPropagation();
        },

        onRunActionNavDropBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list-empty'));

            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onRunActionNavDropCellBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onHideRunActionContainer: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                target.addClass('hidden');
                $(e.currentTarget).find('.run-valView-action-nav-drop-btn').removeClass('active');
            });
        },

        onTargetClick: function (k) {
            var currentAssayTarget = k;
            // var currentAssayTarget = this.assayTargets[k];
            if (currentAssayTarget.id !== this.currentAssayTarget.id) {
                this.wellSelected = [];
            }
            this.currentAssayTarget = currentAssayTarget;
            this.onResetFilter();
        },

        onLegendChange: function (event) {
            if (event.target.checked !== undefined) {
                this.withLegend = event.target.checked;
            } else {
                this.withLegend = !this.withLegend;
            }
            if (this.withLegend) {
                this.ui.chartLegendBtn.addClass('selected');
            } else {
                this.ui.chartLegendBtn.removeClass('selected');
            }
            this.displayGraph();
            this.trigger('well:graph:legend', this.withLegend);
        },

        onRangeSmoothChange: function (event) {
            if (event.target.checked !== undefined) {
                this.rangeSmooth = event.target.checked;
            } else {
                this.rangeSmooth = !this.rangeSmooth;
            }
            if (this.rangeSmooth) {
                this.ui.smoothingBtn.addClass('selected');
            } else {
                this.ui.smoothingBtn.removeClass('selected');
            }
            this.displayGraph();
            this.trigger('well:graph:legend', this.rangeSmooth);
        },

        onRawDataChange: function (event) {
            if (event.target.checked !== undefined) {
                this.rawData = event.target.checked;
            } else {
                this.rawData = !this.rawData;
            }
            if (this.rawData) {
                this.ui.rawDataBtn.addClass('selected');
            } else {
                this.ui.rawDataBtn.removeClass('selected');
            }
            if (!this.rawData) {
                if (this.graphToDisplay.indexOf('A') === 0) {
                    this.currentGraph = 'amplCurvesS';
                } else if (this.graphToDisplay.indexOf('M') === 0) {
                    this.currentGraph = 'meltCurves1S';
                }
            }
            this.displayGraph();
            this.trigger('well:graph:legend', this.rawData);
        },

        // cc color compensation filter - <select-option>
        onCCchange: function (event) {
            if (event.target.checked !== undefined) {
                this.withCC = event.target.checked;
            } else {
                this.withCC = !this.withCC;
            }
            if (this.withCC) {
                this.ui.colorCompensationBtn.addClass('selected');
            } else {
                this.ui.colorCompensationBtn.removeClass('selected');
            }
            this.displayGraph();
        },

        onCVRadioChange: function () {
            if (this.ui.cvRadio) {
                _.each(this.ui.cvRadio, _.bind(function (radio) {
                    if (radio.checked) {
                        this.fixedColor = radio.value === 'yes';
                    }
                }, this));
                this.displayGraph();
            }
        },

        onClickBtnEditRunAt: function () {
            this.createEditRunAtView = new CreateEditRunAtLayoutView({
                model: App.request('runat:model', this.runAt),
                modelRun: this.model,
                newItem: false
            });
            this.createEditRunAtView.show({
                title: _.i18n('runat.edit')
            });
        },

        onFilterClick: function (event) {
            var resultCode = 'none';
            var filterType = event.target.attributes['data-filter-type'].value;
            if (filterType === 'result') {
                resultCode = event.target.attributes['data-result-code'].value;
            }
            var filter = {
                type: filterType,
                resultCode: resultCode
            };
            var filtersList = this.onFiltersListChange(filter);
            this.displayWellsResult(filtersList);
        },

        displayWellsResult: function (filtersList) {
            //------------ utilisation des filtres avec 'ET' logique &&
            var first = true;
            var filteredResult = [];
            _.each(filtersList, _.bind(function (obj) {
                var result = this.getWellsFromFilter(obj);

                if (result === null) {
                    filteredResult = [];
                } else if (filteredResult.length < 1 && first) {
                    filteredResult = result;
                    first = false;
                } else if (filteredResult.length > 0 && result && result.length > 0) {
                    filteredResult = filteredResult.filter(function (n) {
                        return result.indexOf(n) !== -1;
                    });
                }
            }, this));
            if (filteredResult.length < 1 && filtersList.length > 0) {
                filteredResult = ['noResult'];
            }
            this.wellSelected = filteredResult;
            this.trigger('well:filter', this.currentAssayTarget, this.wellSelected);
        },

        getWellsFromFilter: function (filter) {
            var filteredWells = this.wellSelected ? this.wellSelected : [];
            switch (filter.type) {
                case 'result':
                    filteredWells = this.getResultFilteredWell(filteredWells, filter);
                    break;
                case 'errWarn':
                    filteredWells = this.getErrWarnFilteredWell(filteredWells, filter);
                    break;
                case 'repeatStatus':
                    filteredWells = this.getRepeatFilteredWell(filteredWells, filter);
                    break;
            }

            if (filteredWells.length > 0) {
                return filteredWells;
            } else {
                return null;
            }
        },

        getResultFilteredWell: function (filteredWells, filter) {
            var wellSelected = [];
            this.collection.each(_.bind(function (model) {
                if (model.get('refAssay') && model.get('refAssay').get('secId') !== this.currentAssayTarget.assay.assay.secId) {
                    return;
                }
                var target = this.getCurrentResult(model);
                if (target && target.get('result').get('code') === filter.resultCode) {
                    wellSelected.push(model.get('pos'));
                }
            }, this));
            return wellSelected;
        },

        getCurrentResult: function (model) {
            return _.first(model.get('results').filter(_.bind(function (result) {
                return result.get('refAssayResult').get('id') === this.currentAssayTarget.id;
            }, this)));
        },

        getErrWarnFilteredWell: function () {
            var wellSelected = [];
            this.collection.filter(_.bind(function (model) {
                if (model.get('assayVersion') && model.get('assayVersion').get('secId') !== this.currentAssayTarget.assay.secId) {
                    return;
                }
                var target = this.getCurrentResult(model);
                if (target && target.get('codeErr')) {
                    wellSelected.push(model.get('pos'));
                }
            }, this));
            return wellSelected;
        },
        getRepeatFilteredWell: function () {
            var wellSelected = [];
            this.collection.filter(_.bind(function (model) {
                if (model.get('assayVersion') && model.get('assayVersion').get('secId') !== this.currentAssayTarget.assay.secId) {
                    return;
                }
                if (model.get('repeatStatus')) {
                    wellSelected.push(model.get('pos'));
                }
            }, this));
            return wellSelected;
        },

        onFiltersListChange: function (filter) {
            var filtersList = this.options.controller.getFiltersList();
            var add = true;
            if (filtersList.length > 0) {
                filtersList = _.filter(filtersList, _.bind(function (obj) {
                    if (obj.type === filter.type && obj.resultCode === filter.resultCode) {
                        add = false;
                        return false;
                    } else {
                        return true;
                    }
                }, this));
            }
            if (add) {
                filtersList.push(filter);
            }
            this.options.controller.setFiltersList(filtersList);
            return filtersList;
        },

        onResetFilter: function () {
            this.options.controller.setFiltersList([]);
            this.trigger('well:filter', this.currentAssayTarget, [], this.currentGraph);
        },

        /**
         * fill the error-warning filter-box
         */
        fillErrWarnRegionBox: function () {
            var filtersList = this.options.controller.getFiltersList();
            var content = '';
            var count = 0;
            this.collection.each(_.bind(function (model) {
                var result = this.getCurrentResult(model);
                if (result && result.get('codeErr')) {
                    count++;
                }
            }, this));

            if (count > 0) {
                var found = false;
                if (filtersList && filtersList.length > 0) {
                    found = _.find(filtersList, _.bind(function (obj) {
                        return obj.type === 'errWarn';
                    }, this));
                }
                var checkbox = found ? 'checked' : '';
                content =
                    '<div class="headerGroupCell">' +
                    '<div class="groupCellTitle">' + _.i18n('graph.errWarn') + '</div>' +
                    '<div style="display: flex">' +
                    '<div class="valViewHeaderFilterButton clickable hidden-print " code="" ' +
                    'style="margin: 2px;" title="' + _.i18n('graph.res.warnErr') + '">' +
                    '<span class="mdi mdi-alert warningColorIcon js-warning-btn" data-filter-type="errWarn"' +
                    'style="line-height: 19px;font-size: 22px;"></span>' +
                    '<input type="checkbox" class="js-warning-btn" id="checkBox-errWarn" data-filter-type="errWarn" style="top: 10px; position: relative; left: 5px;" ' + checkbox + '>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            }
            this.ui.errWarnCheckBoxRegion.append(content);
        },

        fillRepeatRegionBox: function () {
            var filtersList = this.options.controller.getFiltersList();
            var content = '';
            var count = this.collection.chain().filter(_.bind(function (model) {
                return model.get('repeatStatus');
            }, this)).size().value();

            if (count > 0) {
                var found = false;
                if (filtersList && filtersList.length > 0) {
                    found = _.find(filtersList, _.bind(function (obj) {
                        return obj.type === 'repeatStatus';
                    }, this));
                }
                var checkbox = found ? 'checked' : '';
                content =
                    '<div class="headerGroupCell">' +
                    '<div class="groupCellTitle">' + _.i18n('graph.repeat') + '</div>' +
                    '<div style="display: flex">' +
                    '<div class="valViewHeaderFilterButton clickable hidden-print " code="" ' +
                    'style="margin: 2px;" title="' + _.i18n('graph.res.warnErr') + '">' +
                    '<span class="mdi mdi-play mdi-flip-h js-repeat-btn" data-filter-type="repeatStatus"' +
                    'style="line-height: 19px;font-size: 22px;"></span>' +
                    '<input type="checkbox" class="js-repeat-btn" id="checkBox-repeat" data-filter-type="repeatStatus" style="top: 10px; position: relative; left: 5px;" ' + checkbox + '>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            }
            this.ui.repeatCheckBoxRegion.append(content);
        },

        /**
         * fill the target results filter-box with selected mbAnaResGroup values
         */
        fillTargetResRegionBox: function () {
            var filtersList = this.options.controller.getFiltersList();
            var content = '';
            var results = [];
            var count = [];
            this.collection.each(_.bind(function (model) {
                var result = this.getCurrentResult(model);
                if (!result) {
                    return;
                }
                if (result.get('result')) {
                    results.push(result.get('result'));
                    if (!count[result.get('result').get('code')]) {
                        count[result.get('result').get('code')] = 1;
                    } else {
                        count[result.get('result').get('code')]++;
                    }
                }
            }, this));
            results = _.uniq(results, function (result) {
                return result.get('secId');
            });

            results = results.filter(_.bind(function (result) {
                return result.get('code') !== null;
            }, this));

            if (results.length) {
                content =
                    '<div class="headerGroupCell">' +
                    '<div class="groupCellTitle">' + _.i18n('graph.res') + '</div>' +
                    '<div style="display: flex">';
                _.each(results, _.bind(function (result) {
                    var resultColor = result.get('color');
                    var found = false;
                    if (filtersList && filtersList.length > 0) {
                        found = _.find(filtersList, _.bind(function (obj) {
                            return (obj.type === 'result' && obj.resultCode === result.get('code'));
                        }, this));
                    }
                    var checkbox = found ? 'checked' : '';
                    content = content +
                        '<div class="valViewHeaderFilterButton clickable hidden-print " code="' + result.get('code') + '" ' +
                        'style="margin: 2px;" title="' + result.get('name') + '">' +
                        '<span class="mdi mdi-checkbox-blank-circle js-result-btn" style="line-height: 19px;font-size: 22px;' +
                        'color:' + (resultColor ? resultColor : '') + ';" data-filter-type="result" data-result-code="' + result.get('code') + '"></span>' +
                        '<input type="checkbox" class="js-result-btn" id="checkbox-result-' + result.get('code') + '" ' +
                        'data-result-code="' + result.get('code') + '" data-filter-type="result" style="top: 10px; position: relative; left: 5px;" ' + checkbox + ' value="' + result.get('code') + '">' +
                        '</div>';
                }, this));
                content = content +
                    '</div>' +
                    '</div>';
            }
            this.ui.targetResCheckBoxRegion.append(content);
        },

        onDestroy: function () {
            this.stopListening();
        },

        onTabClick: function (e) {
            if (this.currentGraph === e.currentTarget.attributes['data-graph'].value) {
                return;
            }
            this.currentGraph = e.currentTarget.attributes['data-graph'].value;
            this.displayGraph();
        },
        displayGraph: function () {
            var models = this.collection.filter(_.bind(function (model) {
                return this.wellSelected.includes(model.get('pos'));
            }, this));

            _.each(models, _.bind(function (model) {
                this.listenTo(model, 'change', this.renderSafe);
            }, this));
            if (this.rawData) {
                if (this.graphToDisplay.includes('M')) {
                    this.ui.rawDataMelt.show();
                }
                if (this.graphToDisplay.includes('A')) {
                    this.ui.rawDataAmpl.show();
                }
            } else {
                this.ui.rawDataMelt.hide();
                this.ui.rawDataAmpl.hide();
                this.ui.tab.removeClass('active');
                this.ui.firstTab.addClass('active');
            }

            var view = null;
            switch (this.currentGraph) {
                case 'meltCurvesRaw':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT'
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = true;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurves':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: false
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurvesS':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: true
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurves1':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: false
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurves1S':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: true,
                        hideExtraPoint: !this.rawData
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'amplCurvesRaw':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = true;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: false
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    if (this.runAt) {
                        view.baseline = this.runAt.baseline;
                        view.threshold = this.runAt.threshold;
                    }
                    break;
                case 'amplCurvesS':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: true,
                        hideExtraPoint: !this.rawData
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    if (this.runAt) {
                        view.baseline = this.runAt.baseline;
                        view.threshold = this.runAt.threshold;
                    }
                    break;
                case 'amplCurves1':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: false
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv1Enable = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves1S':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: true
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv1Enable = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves2':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: false
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv2Enable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves2S':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: true
                    });
                    view.setModel(this.model, models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv2Enable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.currentAssayTarget;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
            }

            this.view = view;

            if (this.view) {
                if (this.displaySlider) {
                    this.view.filterValuesX = _.bind(function (min, max) {
                        if (min >= this.view.instance.chart.boxes[2].start && max <= this.view.instance.chart.boxes[2].end) {
                            this.onChangeCbx(min, max);
                        }
                    }, this);
                    this.view.filterValuesY = _.bind(function (min, max) {
                        if (min >= this.view.instance.chart.boxes[3].start && max <= this.view.instance.chart.boxes[3].end) {
                            this.onChangeCby(min, max);
                        }
                    }, this);
                }
                this.getRegion('graph').show(this.view);
            }
        },

        onChangeCbx: function (min, max) {
            var models = this.collection.filter(_.bind(function (model) {
                var b = this.wellSelected.includes(model.get('pos'));
                if (!b) {
                    return b;
                }
                var target = this.getCurrentResult(model);
                if (!target) {
                    return false;
                }
                return target.get('cts').length > 0 && target.get('cts')[0] > min && target.get('cts')[0] < max;
            }, this));

            this.wellSelected = [];
            _.each(models, _.bind(function (model) {
                this.wellSelected.push(model.get('pos'));
            }, this));
            this.trigger('well:filter', this.currentAssayTarget, this.wellSelected, this.currentGraph);
        },


        onChangeCby: function (min, max) {
            var models = this.collection.filter(_.bind(function (model) {
                var b = this.wellSelected.includes(model.get('pos'));
                if (!b) {
                    return b;
                }
                var target = this.getCurrentResult(model);
                if (!target) {
                    return false;
                }

                var lastCurvValue = target.get('curves').first();
                if (!lastCurvValue) {
                    return false;
                }
                lastCurvValue = lastCurvValue.get('lines');

                var lastValue;
                var index = 0;
                switch (this.currentGraph) {
                    case 'amplCurvesRaw':
                    case 'meltCurvesRaw':
                        _.each(lastCurvValue, function (value, i) {
                            if (value.raw) {
                                index = i;
                            }
                        });
                        lastValue = lastCurvValue[index].raw;
                        break;
                    case 'amplCurves':
                    case 'meltCurves':
                        _.each(lastCurvValue, function (value, i) {
                            if (value.data) {
                                index = i;
                            }
                        });
                        lastValue = lastCurvValue[index].data.raw;
                        break;
                    case 'amplCurvesS':
                    case 'meltCurvesS':
                        _.each(lastCurvValue, _.bind(function (value, i) {
                            if (this.rawData && value.data) {
                                index = i;
                            } else if (!this.rawData && value.dataD2) {
                                index = i;
                            }
                        }, this));
                        lastValue = lastCurvValue[index].data.smooth;
                        break;
                    case 'amplCurves1':
                    case 'meltCurves1':
                        lastValue = _.chain(lastCurvValue).filter(function (value) {
                            return value.dataD1;
                        }).map(function (value) {
                            return value.dataD1.raw;
                        }).max().value();
                        break;
                    case 'amplCurves1S':
                    case 'meltCurves1S':
                        lastValue = _.chain(lastCurvValue).filter(function (value) {
                            return value.dataD1;
                        }).map(function (value) {
                            return value.dataD1.smooth;
                        }).max().value();
                        break;
                    case 'amplCurves2':
                        lastValue = _.chain(lastCurvValue).filter(function (value) {
                            return value.dataD2;
                        }).map(function (value) {
                            return value.dataD2.raw;
                        }).max().value();
                        break;
                    case 'amplCurves2S':
                        lastValue = _.chain(lastCurvValue).filter(function (value) {
                            return value.dataD2;
                        }).map(function (value) {
                            return value.dataD2.smooth;
                        }).max().value();
                        break;
                }

                lastValue = Number(lastValue);
                if (lastValue === undefined || _.isNaN(lastValue)) {
                    return false;
                }
                return lastValue > min && lastValue < max;
            }, this));

            this.wellSelected = [];
            _.each(models, _.bind(function (model) {
                this.wellSelected.push(model.get('pos'));
            }, this));
            this.trigger('well:filter', this.currentAssayTarget, this.wellSelected, this.currentGraph);
        },

        onCurveClick: function (wellSecId) {
            var model = this.collection.findWhere({secId: wellSecId});
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails(model, this.wellSelected, null);
            }, this));
        },

        onConfirm: function () {
            this.triggerMethod('saved');
            this.hide();
        },

        hide: function () {
            $('#main-region').removeClass('hidden-print');
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
});

