define('prepRunTabsLayoutView',[
	'module',
	'jquery',
    'underscore',
	'app',
	'marionette',
	'template!prepRunTabsTpl',
	'prepRunTabsLayoutHeaderView',
    'entities/admin/codelist',
	'entities/caccounts/pcrruns'
], function (
	module,
	$,
    _,
	App,
	Marionette,
	prepRunTabsTpl,
	PrepRunTabsLayoutHeaderView,
    CodeLists
) {
	'use strict';

	module.exports = Marionette.LayoutView.extend({
		template: prepRunTabsTpl,

		regions: {
			tabs: '.js-tabs-region',
			mainHeader: '.tab-layout-main-header-region'
		},

		onShow: function () {
            CodeLists.findItemsByCode('PREPRUN_ST').done(_.bind(function (data) {
			var opacity = '1'; //block
			if (this.currentTab === 'tracs' || App.getCurrentRoute().search('tracs') >= 0) {
				opacity = '0.2'; //none
			}

			this.prepRunTabsLayoutHeaderView = new PrepRunTabsLayoutHeaderView({
				model: this.options.model,
				tabs: this.options.tabs,
				edit: this.options.edit,
				from: this.options.from,
				step: this.options.step,
				to: this.options.to,
				opacity: opacity,
				tabName: this.options.tabName,
                codeListElements: data
			});
			this.getRegion('mainHeader').show(this.prepRunTabsLayoutHeaderView);
			this.prepRunTabsLayoutHeaderView.triggerMethod('show:active', this.options.tabName, '');
            }, this));
		},

		serializeData: function () {
			return {};
		}
	});
});

