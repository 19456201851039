
define('template!prepWellToolTipView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row" style="height: 22px; color: white;">\n    <div class="col-xs-2" style="bottom: 5px; display: flex;">\n        ';
 if (smpType === 'NC') { ;
__p += ' <!-- smpType === \'NC\' -->\n        <span class="mdi-content-remove-circle-outline pcrTooltip-smpType-icon"\n              style="font-size: 22px; position: relative;"></span>\n        ';
 } else if(smpType === 'PC') { ;
__p += ' <!-- smpType === \'PC\' -->\n        <span class="mdi-content-add-circle-outline pcrTooltip-smpType-icon"\n              style="font-size: 22px; position: relative;"></span>\n        ';
 } else if(smpType === 'RC' ) { ;
__p += ' <!-- smpType === \'RC\' -->\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="mdi-registered-trademark"></span>\n        ';
 } else if(smpType === 'C') { ;
__p += ' <!-- smpType === \'C\' -->\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="ant-design:copyright-circle-outlined"></span>\n        ';
 } else if(smpType === 'P') { ;
__p += ' <!-- smpType === \'P\' Pool -->\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="mdi:google-circles-communities"></span>\n        ';
 } else if(smpType === 'OC') { ;
__p += '\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="ant-design:compass-outlined"></span>\n        ';
 } else if(smpType === 'D') { ;
__p += '\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="fa6-solid:d"></span>\n        ';
 } else if(smpType === 'C') { ;
__p += '\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="fa6-solid:c"></span>\n        ';
 } else if(smpType === 'U') { ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (repeatStatus) { ;
__p += '\n        <div style="height: 22px; width: 22px;">\n            <div class="repeatStatus repeat-' +
((__t = ( repeatStatus )) == null ? '' : __t) +
'"></div>\n        </div>\n        ';
 if(dilution) { ;
__p += '\n        <div>' +
((__t = ( dilution )) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if(pooled) { ;
__p += '\n        <span class="iconify" style="font-size: 22px; position: relative;"\n              data-icon="ic:outline-adjust"></span>\n        ';
 } ;
__p += '\n    </div>\n    <div class="col-xs-2">\n        ' +
((__t = ( pos )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-xs-6" style="overflow: hidden;">\n        ' +
((__t = ( smpId )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-xs-2 ' +
((__t = ( concatComment ? 'commentWell' : '')) == null ? '' : __t) +
'">\n        ' +
((__t = ( concatComment )) == null ? '' : __t) +
'\n    </div>\n</div>\n\n<div class="m-t-0 m-b-5" style="border-bottom: 1px solid;"></div>\n\n<div class="col-xs-12 p-l-0 p-r-0 m-b-10">\n    <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n        <div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n            <div class="col-w-all f-l d-f col-w-5">' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'</div>\n            <div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;">' +
((__t = (
                assaysToDisplay.waiting )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="col-xs-12 p-l-0 p-r-0 m-b-10">\n    <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n        <div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n            <div class="col-w-all f-l d-f col-w-5">' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'</div>\n            <div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;"> ' +
((__t = (
                assaysToDisplay.running )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="col-xs-12 p-l-0 p-r-0 m-b-10">\n    <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n        <div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n            <div class="col-w-all f-l d-f col-w-5">' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'</div>\n            <div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;">' +
((__t = (
                assaysToDisplay.done )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    </div>\n</div>\n\n';
 if (wellsPool && wellsPool.length > 0) { ;
__p += '\n<div class="col-xs-12 m-b-10 p-t-5" style="border: solid 1px; border-radius: 8px;">\n    ';
 wellsPool.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n    ';
 _.each(wellsPool, function(well) { ;
__p += '\n    <div class="m-b-0 col-xs-12">\n        <label class="col-xs-5 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n    </div>\n    ';
 }); ;
__p += '\n</div>\n';
 } ;
__p += '\n\n';
 if (wellsPooled && wellsPooled.length > 0) { ;
__p += '\n<div class="col-xs-12 m-b-10 p-t-5" style="border: solid 1px; border-radius: 8px;">\n    ';
 wellsPooled.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n    ';
 _.each(wellsPooled, function(well) { ;
__p += '\n    <div class="m-b-0 col-xs-12">\n        <label class="col-xs-6 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n    </div>\n    ';
 }); ;
__p += '\n</div>\n';
 } ;
__p += '\n';

}
return __p
};});

