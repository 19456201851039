define('entities/caccounts/pcrruns',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'moment',
    'customBootboxMessage',
    'module',
    'dynamic',
    'marionette',
    'template!pcrRunStatusTpl',
    'template!pcrRunValStatusTpl',
    'entities/caccounts/attachment',
    'entities/caccounts/assay',
    'entities/caccounts/assayVersion',
    'entities/caccounts/prepruns',
    'backboneRelational',
    'entities/caccounts/pcrwells',
    'entities/caccounts/cyclers',
    'entities/admin/dye'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    moment,
    CustomBootboxMessage,
    module,
    Dynamic,
    Marionette,
    StatusToolTip,
    ValStatusToolTip,
    Attachments
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PcrRun = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'name': '',
            'creatDate': null,
            'modDate': null,
            'plateSize': 'H12',
            'comment': '',
            'wells': [],
            'refModUser': {},
            'refCreatUser': {},
            'refRespUser': {},
            'settings': {},
            'toBePooled': true,
            'period': '',
            'periodFormatted': '',
            'group': '',
            'sequence': 0,
            'sequenceFormat': '',
            'pattern': '',
            'suffix': '',
            'assays': [],
            'plateIdPC': '',
            'refLmbCycler': null,
            'refKitProt': null,
            'status': 0,
            'valStatus': '',
            'exported': true,
            'type': '',
            'department': '',
            'archived': false,
            'archivedDate': '',
            'prepRuns': [],
            'hasColorCompensation': false,
            'assayVersions': []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection',
            reverseRelation: {
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'prepRuns',
            relatedModel: 'PrepRun',
            collectionType: 'PrepRunCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assayVersions',
            relatedModel: 'AssayVersion',
            collectionType: 'AssayVersionCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'Cycler'
        }, {
            type: Backbone.HasMany,
            key: 'dyes',
            relatedModel: 'Dye',
            collectionType: 'DyeCollection'
        }],

        getType: function () {
            return 'PcrRun';
        },

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/runs/pcr');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        saveHeader: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            var rubKitProtSecId = this.get('refKitProt') && this.get('refKitProt').secId ? this.get('refKitProt').secId : null;
            var runLmbCyclerSecId = this.get('refLmbCycler') && this.get('refLmbCycler').secId ? this.get('refLmbCycler').secId : null;
            var cyclerSecId = this.get('refCycler') && this.get('refCycler').get('secId') ? this.get('refCycler').get('secId') : null;
            var cyclerPublicSecId = this.get('refCyclerPublic') && this.get('refCyclerPublic').secId ? this.get('refCyclerPublic').secId : null;
            var refRespUserSecId = this.get('refRespUser') && this.get('refRespUser').secId ? this.get('refRespUser').secId : null;

            var body = {
                name: this.get('name'),
                status: this.get('status'),
                period: this.get('period'),
                department: this.get('department'),
                group: this.get('group'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix'),
                pattern: this.get('pattern'),
                plateId: this.get('plateIdPC'),
                kitProtSecId: rubKitProtSecId,
                lmbCyclerSecId: runLmbCyclerSecId,
                comment: this.get('comment'),
                respUserSecId: refRespUserSecId,
                cyclerSecId: cyclerSecId,
                cyclerPublicSecId: cyclerPublicSecId
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        copyPlateLayoutFrom: function (request) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/copyPlateLayoutFrom'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(request),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        // import result
        updateWithXmlFile: function (files, params) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId'), params);

            _.each(files, function (file) {
                formdata.append('file', file);
            });


            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                timeout: 300000,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        // import plate Layout
        importFilePlateLayout: function (fileInfo, fileFormatSecId) {
            var param;

            param = {fileFormatSecId: fileFormatSecId};

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/plateLayout/import', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        // import sample list
        importFileSampleList: function (fileInfo, request) {
            var param = {
                fileFormatSecId: request.get('refFileFormat').secId,
                startPos: request.get('startPos'),
                plateFillDirection: request.get('plateFillDirection')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/sampleList/import', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        getRefCycler: function () {
            console.error('%cPlease check pcrRuns.getRefCycler(); use pcrRun.hasColorCompensation property(boolean) instead"', 'color: #00f8ff');
        },

        getRunAtFromTarget: function (targetSecId) {
            console.error('%cPlease check pcrRuns.getRunAtFromTarget()"', 'color: #00f8ff');
            var runAt = _.find(this.get('runAts'), function (runAt) {
                return (runAt.refKitP.secId === targetSecId);
            });
            return runAt;
        },

        exportResult: function (json) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/export/result'),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                data: json ? JSON.stringify(json) : null,
                contentType: 'application/json',
                dataType: 'json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        validation2: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/validation2'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (response) {
                    this.fetch().done(function () {
                        defer.resolve(response);
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        exportList: function (type) {
            var params = {
                type: type
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/export/list', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        extractOrderIdFromSampleId: function (sampleId) {
            var sidOrderId = this.get('settings').SID;

            if (sidOrderId && sampleId) {
                var orderIdIsIncludedInSid = sidOrderId.split(';')[7];
                var orderIdStartPos = sidOrderId.split(';')[8];
                var orderIdEndPos = sidOrderId.split(';')[9];

                if (orderIdIsIncludedInSid === 'Y') {
                    sampleId = sampleId.substr(Number(orderIdStartPos) > 0 ? Number(orderIdStartPos) - 1 : Number(orderIdStartPos),
                        Number(orderIdEndPos));
                }
            }
            return sampleId;
        },

        getGeneratePeriodFormatted: function (int64Date) {
            var params = {
                period: int64Date
            };
            var url = Settings.url('compuzz', 'v2/runs/pcr/generatePeriodFormatted', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        availableRunName: function () {
            var params = {
                runName: this.get('name')
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/availableRunName', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        generateRunName: function () {
            var params = {
                pattern: this.get('pattern'),
                department: this.get('department'),
                group: this.get('group'),
                period: this.get('period') ? Number(moment(this.get('period')).format('x')) : null,
                sequenceFormat: this.get('sequenceFormat'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix')
            };
            var url = Settings.url('compuzz', 'v2/runs/pcr/generateRunName', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: _.bind(function (resp) {
                    // this.set('name', resp);
                    defer.resolve(resp);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        refreshData: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/refreshResult'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        reloadCurves: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/reloadCurves'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        applyAssayWells: function (assaySecId, startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/assay/assign', {
                assaySecId: assaySecId,
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        replaceAssayWells: function (assayReplaceSecId, assayNewSecId, startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/assay/replace', {
                assayReplaceSecId: assayReplaceSecId,
                assayNewSecId: assayNewSecId,
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        applyTestWells: function (startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/test/add', {
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearAssay: function (from, to, assay) {
            var params = {
                from: from,
                to: to,
                assaySecId: assay.secId
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/assay/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        removeTest: function (from, to) {
            var params = {
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/test/remove', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        clearWells: function (from, to) {
            var params = {
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        generateSampleId: function (sampleIds) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (from, to) {
            var params = {
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        getPreviousRun: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/previous'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getNextRun: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/next'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        controlSample: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/samples/control'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runJson) {
                    defer.resolve(runJson);
                }
            });
            return defer.promise();
        },

        startScanning: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/scanning/start'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        stopScanning: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/scanning/stop'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this)
            });
            return defer.promise();
        },

        findSelectedModel: function (posSelected) {
            var wellSecId = [];
            _.each(this.get('wells').filter(function (model) {
                return posSelected.includes(model.get('pos'));
            }), function (model) {
                wellSecId.push(model.get('secId'));
            });
            return wellSecId;
        }, /**
         * Validation 1 for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        validationWells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/validation/' + assayResult.id);
            return this.callAction(defer, url, wellSecId);
        },

        callAction: function (defer, url, wellSecId) {
            wellSecId = JSON.stringify(this.findSelectedModel(wellSecId));
            $.ajax({
                url: url,
                type: 'PUT',
                data: wellSecId,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 2 for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        validation2Wells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/validation2/' + assayResult.id);

            return this.callAction(
                defer, url, wellSecId);
        },

        /**
         * Validation 12 for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        validation12Wells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/validation12/' + assayResult.id);

            return this.callAction(
                defer, url, wellSecId);
        },

        /**
         * Negative result for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        negativeWells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/negative/' + assayResult.id);

            return this.callAction(
                defer, url, wellSecId);
        },

        /**
         * Repeat PCR technical for specific target on specific wells
         * @param {Array} wellSecId
         * @returns {*|jQuery}
         */
        repeatPCRWells: function (wellSecId) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/repeat/PT');

            return this.callAction(
                defer, url, wellSecId);
        },

        /**
         * Repeat PCR control for specific target on specific wells
         * @param {Array} wellSecId
         * @returns {*|jQuery}
         */
        repeatPCRControlWells: function (wellSecId) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/repeat/PC');

            return this.callAction(defer, url, wellSecId);
        },
        /**
         * Repeat PCR technical for specific target on specific wells
         * @param {Array} wellSecId
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionWells: function (wellSecId, dillution) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/repeat/XT', {'dillution': dillution});

            return this.callAction(defer, url, wellSecId);
        },

        /**
         * Repeat Extraction control for specific target on specific wells
         * @param {Array} wellSecId
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionControlWells: function (wellSecId, dillution) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/repeat/XC', {'dillution': dillution});

            return this.callAction(
                defer, url, wellSecId);
        },

        /**
         * Confirm result for specific target on specific wells
         * @param {Array} wellSecId
         * @param {Object} assayResult
         * @returns {*|jQuery}
         */
        confirmWells: function (wellSecId, assayResult) {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/confirm/' + assayResult.id);


            return this.callAction(
                defer, url, wellSecId);
        },

        /**
         * Find pcrKitLot for a pcr runs
         * @returns {*|jQuery}
         */
        getPcrKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/kitLots');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getWellRepeat: function (sampleId, assaySecId, otherAssay) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/wells/' + sampleId, {
                    assaySecId: assaySecId,
                    loadWells: false,
                    otherAssay: otherAssay
                }),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (wells) {
                    defer.resolve(wells);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        },

        addPrepWells: function (wellsKeyValue, assaySecId) {
            var params = {
                assaySecId: assaySecId
            };
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/addPrepWell', params),
                defer = $.Deferred();
            $.ajax({
                method: 'post',
                url: url,
                data: JSON.stringify(wellsKeyValue),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (response) {
                    this.fetch().done(function () {
                        defer.reject(response);
                    });
                }, this)
            });
            return defer.promise();
        },

        exportPlateLayout: function (params) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/plateLayout/exportByCycler', params),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response, ignored, header) {
                    defer.resolve(header.getAllResponseHeaders().includes('filename'), header.getResponseHeader('content-disposition') ? header.getResponseHeader('content-disposition').substring(header.getResponseHeader('content-disposition').indexOf('filename=') + 9) : null, response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        findAssayBySecId: function (assaySecId) {
            return this.get('assays').findWhere({secId: assaySecId});
        },
        canAssignSID: function () {
            return this.get('wells').filter(function (pcrWell) {
                return pcrWell.canChangeSmpId();
            }).length > 0;
        },
        canAssignAssay: function () {
            return this.get('wells').any(function (well) {
                return !well.get('refAssay') || !well.get('refAssay').get('secId');
            });
        },
        canClearAssay: function () {
            return this.get('wells').every(function (pcrWell) {
                return pcrWell.get('valWst') !== 5;
            }) && this.get('wells').any(function (pcrWell) {
                return pcrWell.get('refAssay') !== null;
            });
        },
        getEntity: function () {
            return module.exports;
        },
        getAttachments: function () {
            return Attachments.findAll(this.getEntity().getName(), this.get('secId'));
        },
        findPrepRuns: function () {
            return new Backbone.Collection(_.filter(_.uniq(this.get('wells').map(function (well) {
                return well.get('refPrepWell') ? well.get('refPrepWell').get('refPrepRun') : null;
            })), function (prepRun) {
                return prepRun;
            }));
        },
        findAssayLines: function () {
            var assayLines = new Backbone.Collection();
            _.each(_.uniq(_.filter(this.get('wells').pluck('refAssay'), function (assay) {
                return assay;
            })), _.bind(function (assay) {
                var pcrW = this.get('wells').filter(function (well) {
                    return well.get('refAssay') === assay;
                });
                var assayLine = {
                    assay: {code: assay.get('code')},
                    countByType: {
                        S: 0,
                        D: 0,
                        PC: 0,
                        NC: 0,
                        RC: 0,
                        OC: 0
                    },
                    volumeBySample: assay.get('volumeBySample')
                };
                _.each(_.keys(assayLine.countByType), function (type) {
                    assayLine.countByType[type] = _.filter(pcrW, function (well) {
                        return _.isEqual(well.get('smpType'), type);
                    }).length;
                });
                assayLines.push(assayLine);
            }, this));
            return assayLines;
        },
        generateSummary: function () {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + this.get('secId') + '/report');
            window.open(url, '_blank');
        },
        getTypeWells: function () {
            var types = this.get('wells').map(function (well) {
                return well.getType();
            });
            if (_.any(types, function (type) {
                return type === 'MAGPIX';
            })) {
                return 'MAGPIX';
            }
            if (_.any(types, function (type) {
                return type === 'HL7';
            })) {
                return 'HL7';
            }
            return 'CURVE';
        }
    });

    app.ns.PcrRunCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/runs/pcr');
        },
        model: app.ns.PcrRun
    });

    var API = {

        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['status', 'valStatus', 'plateIdPC']};
            // 'name' must be showed by _getAutocompleteOptions(valueProperty)
        },

        getStatusFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name']};
            // 'code' must be showed by _getAutocompleteOptions(valueProperty)
        },

        getTypeFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name']};
            // 'code' must be showed by _getAutocompleteOptions(valueProperty)
        },

        getCollectionUrl: function () {
            new app.ns.PcrRunCollection();
            return Settings.url('compuzz', 'v2/runs/pcr');
        },

        getAll: function (params) {
            var url = Settings.url('compuzz', 'v2/runs/pcr', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runs) {
                    var runsList = [];
                    _.each(runs, function (run) {
                        runsList.push(run.secId);
                    });
                    defer.resolve(runsList);
                }
            });

            return defer.promise();
        },

        getCountUrl: function () {
            return 'v2/runs/pcr/rowCount';
        },

        getCount: function () {
            var url = Settings.url('compuzz', 'v2/pcr/rowCount'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                success: function (count) {
                    defer.resolve(count);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
        },

        archiveRun: function (secId) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + secId + '/archive'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        unArchiveRun: function (secId) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + secId + '/unarchive'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        archiveRunByList: function (secIds) {
            var params = {
                secIds: secIds
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/archive', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        unArchiveRunByList: function (secIds) {
            var params = {
                secIds: secIds
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/unarchive', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PcrRunJson.pdf');
        },

        fromTemplate: function (templateSecId, params) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/fromTemplate/' + templateSecId, params),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                success: function (run) {
                    defer.resolve(run);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        fromTemplate384: function (templateSecId, params) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/fromTemplate384/' + templateSecId, params),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                success: function (run) {
                    defer.resolve(run);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        updateFromTemplate: function (runSecId, templateSecId) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/fromTemplate/' + templateSecId),
                defer = $.Deferred();

            $.ajax({
                method: 'put',
                url: url,
                success: function (resp) {
                    var model = new app.ns.PcrRun(resp);
                    defer.resolve(model);
                    // todo move from api to model method
                }
            });

            return defer.promise();
        },

        createFromPrep: function (json) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/createFromPrep'),
                defer = $.Deferred();

            json.cyclerPublicSecId = json.refCycler.secId;
            json.assaySecId = json.refAssay.secId;
            json.pcrRunTemplateSecId = json.refPcrRunTemplate.secId;
            json.respUserSecId = json.respUser ? json.respUser.secId : null;

            $.ajax({
                method: 'post',
                url: url,
                data: JSON.stringify(json),
                contentType: 'application/json',
                dataType: 'json',
                success: function (resp) {
                    defer.resolve(resp);
                }
            });
            return defer.promise();
        },

        getSampleIds: function (runSecId, sampleId) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/wells/' + sampleId),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runs) {
                    var wells = [], run, well, j, i;
                    for (i = 0; i < runs.length; i++) {
                        run = runs[i];
                        for (j = 0; j < run.wells.length; j++) {
                            well = run.wells[j];
                            well.refRun = run;
                            wells.push(well);
                        }
                    }
                    defer.resolve(wells);
                }
            });

            return defer.promise();
        },

        getWellSampleIdsCollectionUrl: function (runSecId, sampleId, params) {
            if (!params) {
                params = {};
            }
            return Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/wells/' + sampleId, params);
        },

        countWellSampleIdsCollectionUrl: function (runSecId, sampleId, params) {
            var stringParams = '',
                ampersand = '?';
            _.each(params, function (value, key) {
                if ((value || value === 0) && key) {
                    stringParams += (ampersand + key + '=' + value.toString());
                    ampersand = '&';
                }
            });
            return 'v2/runs/pcr/' + runSecId + '/wells/' + sampleId + '/rowCount' + stringParams;
        },

        getWellQueryCollectionUrl: function (params) {
            if (!params) {
                params = {};
            }
            return Settings.url('compuzz', 'v2/runs/pcr/wells/find', params);
        },

        countWellQueryCollectionUrl: function (params) {
            var stringParams = '',
                ampersand = '?';
            _.each(params, function (value, key) {
                if ((value || value === 0) && key) {
                    stringParams += (ampersand + key + '=' + value.toString());
                    ampersand = '&';
                }
            });
            return 'v2/runs/pcr/wells/find/rowCount' + stringParams;
        },

        availableForRunTemplate: function (runSecId, params) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/availableFromRunTemplate/' + runSecId, params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (count) {
                    defer.resolve(count);
                }
            });

            return defer.promise();
        },

        pcrSetupSameLayoutSelected: function (runSecId, kitProtSecId, assaySecId) {
            var params = {
                kitProtSecId: kitProtSecId,
                assaySecId: assaySecId
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/pcrSetup/selected', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },

        pcrSetupSameLayoutCopy: function (runSecId, kitProtSecId, assaySecId) {
            var params = {
                kitProtSecId: kitProtSecId,
                assaySecId: assaySecId
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/pcrSetup/copy', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (newRunSecId) {
                    defer.resolve(newRunSecId);
                }
            });

            return defer.promise();
        },

        exportResASTM: function (runSecId, type, params) {

            var url = Settings.url('exportUrl', runSecId + '/' + type.toUpperCase(), params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (countExported) {
                    defer.resolve('export.external.success', countExported);
                },
                fail: function () {
                    defer.reject('export.external.failed');
                },
                error: function () {
                    defer.reject('export.external.failed');
                }
            });

            return defer.promise();
        },

        plateLayoutToImport: function () {

            var url = Settings.url('compuzz', 'import/plateLayoutPcr/files'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },

        deleteFileRemote: function (fileName) {

            var url = Settings.url('compuzz', 'import/result/file', {fileName: fileName}),
                defer = $.Deferred();

            $.ajax({
                method: 'delete',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },

        getWellCollectionUrl: function (runSecId) {
            var params = {};
            return Settings.url('compuzz', 'v2/runs/pcr/' + runSecId + '/runtracs', params);
        },

        getWellsCountUrl: function (runSecId) {
            var params = '?';
            return 'v2/runs/pcr/' + runSecId + '/runtracs/rowCount' + params;
        },

        createRunFromPlateLayout: function (fileInfo, fileFormatSecId) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    fileFormatSecId: fileFormatSecId
                };

            var url = Settings.url('compuzz', 'v2/runs/pcr/fromPlateLayout', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function () {
                    defer.reject();
                }
            });

            return defer.promise();
        },

        createRunFromSampleList: function (fileInfo, request) {
            var defer = $.Deferred(),
                formdata = new FormData();

            var param = {
                fileFormatSecId: request.get('refFileFormat').secId,
                startPos: request.get('startPos'),
                plateFillDirection: request.get('plateFillDirection')
            };

            var url = Settings.url('compuzz', 'v2/runs/pcr/fromSampleList', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function () {
                    defer.reject();
                }
            });

            return defer.promise();
        },

        controlSID: function (model, checkLength, checkDuplicate) {
            var defer = $.Deferred();
            model.controlSample().done(_.bind(function (controlSampleResponse) {
                if ((controlSampleResponse.duplicateSampleId && Object.getOwnPropertyNames(controlSampleResponse.duplicateSampleId).length > 0) ||
                    (controlSampleResponse.sampleClassWithoutLIS && Object.getOwnPropertyNames(controlSampleResponse.sampleClassWithoutLIS).length > 0) ||
                    controlSampleResponse.sampleDiversCount > 0) {
                    var text = '';
                    var textSet = false;

                    var wrongSmpIdCount = 0;
                    _.each(controlSampleResponse.sampleClassWithoutLIS, function () {
                        wrongSmpIdCount++;
                    });

                    if (checkLength && wrongSmpIdCount > 0) {
                        textSet = true;
                        text = _.i18n('plateLayout.sampleId.sampleClassWithoutLis') + '<br/><div class="row">';

                        text = text + '<div class="col-xs-4">' + _.i18n('sampleClass') + '</div>';
                        text = text + '<div class="col-xs-4">' + _.i18n('well') + '</div>';
                        text = text + '<div class="col-xs-4">' + _.i18n('sampleId') + '</div>';
                        _.each(controlSampleResponse.sampleClassWithoutLIS, function (wells, p) {
                            _.each(wells, function (pos) {
                                text = text + '<div class="col-xs-4" style="font-weight: normal;">' + p + '</div>';
                                text = text + '<div class="col-xs-4" style="font-weight: normal;">' + pos + '</div>';
                                var wellModel = model.get('wells').findWhere({pos: pos});
                                text = text + '<div class="col-xs-4" style="font-weight: normal;">' + (wellModel ? wellModel.get('smpId').get('code') : '') + '</div>';
                            });
                        });
                        text = text + '</div>';
                    }

                    var duplicateCount = 0;
                    _.each(controlSampleResponse.duplicateSampleId, function () {
                        duplicateCount++;
                    });

                    if (checkDuplicate && duplicateCount > 0) {
                        textSet = true;
                        text = text + _.i18n('plateLayout.sampleId.duplicate') + '<br/><div class="row">';

                        for (var i in controlSampleResponse.duplicateSampleId) {
                            text = text + '<div>' + i + '</div>' +
                                '<div><ul>';
                            for (var j in controlSampleResponse.duplicateSampleId[i]) {
                                text = text + '<li>' + controlSampleResponse.duplicateSampleId[i][j] + '</li>';
                            }
                            text = text + '</ul></div>';
                        }
                        text = text + '</div><br/>';
                    }

                    if (controlSampleResponse.sampleDiversCount > 0) {
                        textSet = true;
                        text = text + _.i18n('plateLayout.sampleId.divers') + ' : ' + controlSampleResponse.sampleDiversCount;
                    }

                    if (textSet) {
                        var params = {
                            message: text,
                            type: 'info'
                        };
                        CustomBootboxMessage.customAlert(params);
                    }

                } else {
                    CustomBootboxMessage.customThumbUpNotification();
                }
                defer.resolve();
            }, this));
            return defer.promise();
        }
    };

    App.reqres.setHandler('run:pcr:get-collection-url', function () {
        return API.getCollectionUrl();
    });

    App.reqres.setHandler('run:pcr:get-collection-to-page', function (params) {
        return API.getAll(params);
    });

    App.reqres.setHandler('run:pcr:get-count-url', function () {
        return API.getCountUrl();
    });

    App.reqres.setHandler('run:pcr:get-count', function () {
        return API.getCount();

    });

    App.reqres.setHandler('run:pcr:archive', function (secId) {
        return API.archiveRun(secId);
    });

    App.reqres.setHandler('run:pcr:unarchive', function (secId) {
        return API.unArchiveRun(secId);
    });

    App.reqres.setHandler('run:pcr:archive:bylist', function (secIds) {
        return API.archiveRunByList(secIds);
    });

    App.reqres.setHandler('run:pcr:unarchive:bylist', function (secIds) {
        return API.unArchiveRunByList(secIds);
    });

    App.reqres.setHandler('run:pcr:from-template', function (templateSecId, params) {
        return API.fromTemplate(templateSecId, params);
    });

    App.reqres.setHandler('run:pcr:from-template384', function (templateSecId, params) {
        return API.fromTemplate384(templateSecId, params);
    });

    App.reqres.setHandler('run:pcr:get-sampleids', function (runSecId, sampleId) {
        return API.getSampleIds(runSecId, sampleId);
    });

    App.reqres.setHandler('run:pcr:get-collection-sampleids-url', function (runSecId, sampleId, params) {
        return API.getWellSampleIdsCollectionUrl(runSecId, sampleId, params);
    });

    App.reqres.setHandler('run:pcr:count-collection-sampleids-url', function (runSecId, sampleId, params) {
        return API.countWellSampleIdsCollectionUrl(runSecId, sampleId, params);
    });

    App.reqres.setHandler('run:pcr:get-collection-query-url', function (params) {
        return API.getWellQueryCollectionUrl(params);
    });

    App.reqres.setHandler('run:pcr:count-collection-query-url', function (params) {
        return API.countWellQueryCollectionUrl(params);
    });

    App.reqres.setHandler('run:pcr:import-layout-from-template', function (runSecId, templateSecId) {
        return API.updateFromTemplate(runSecId, templateSecId);
    });

    App.reqres.setHandler('run:pcr:create-from-prep', function (json) {
        return API.createFromPrep(json);
    });

    App.reqres.setHandler('run:pcr:available-for-run-template', function (runSecId, params) {
        return API.availableForRunTemplate(runSecId, params);
    });

    App.reqres.setHandler('run:pcr:setup-same-layout-selected', function (runSecId, kitProtSecId, assaySecId) {
        return API.pcrSetupSameLayoutSelected(runSecId, kitProtSecId, assaySecId);
    });

    App.reqres.setHandler('run:pcr:setup-same-layout-copy', function (runSecId, kitProtSecId, assaySecId) {
        return API.pcrSetupSameLayoutCopy(runSecId, kitProtSecId, assaySecId);
    });

    App.reqres.setHandler('run:pcr:export-external', function (runSecId, type, params) {
        return API.exportResASTM(runSecId, type, params);
    });
    App.reqres.setHandler('run:pcr:plateLayout-to-import', function () {
        return API.plateLayoutToImport();
    });

    App.reqres.setHandler('run:pcr:delete-remote-file', function (fileName) {
        return API.deleteFileRemote(fileName);
    });

    App.reqres.setHandler('run:pcr:trac:get-collection-url', function (runSecId) {
        return API.getWellCollectionUrl(runSecId);
    });

    App.reqres.setHandler('run:pcr:trac:get-collection', function (runSecId) {
        var colUrl = API.getWellCollectionUrl(runSecId);
        var Col = App.request('trac:pcr:collection');
        Col.url = colUrl;
        return Col;
    });

    App.reqres.setHandler('run:pcr:trac:get-count-url', function (runSecId) {
        return API.getWellsCountUrl(runSecId);
    });

    App.reqres.setHandler('run:pcr:trac:get-collection-result-url', function (runSecId) {
        return API.getWellResultCollectionUrl(runSecId);
    });

    App.reqres.setHandler('run:pcr:get-fields-to-display', function () {
        return API.getFieldsToDisplay();
    });

    App.reqres.setHandler('run:pcr:status:get-fields-to-display', function () {
        return API.getStatusFieldsToDisplay();
    });

    App.reqres.setHandler('run:pcr:type:get-fields-to-display', function () {
        return API.getTypeFieldsToDisplay();
    });

    App.reqres.setHandler('run:pcr:from:plateLayout', function (fileInfo, fileFormatSecId) {
        return API.createRunFromPlateLayout(fileInfo, fileFormatSecId);
    });
    App.reqres.setHandler('run:pcr:from:sampleList', function (fileInfo, request) {
        return API.createRunFromSampleList(fileInfo, request);
    });

    App.reqres.setHandler('run:pcr:controlSID', function (model, checkLength, checkDuplicate) {
        return API.controlSID(model, checkLength, checkDuplicate);
    });

    module.exports = _.defaults({

        // A finir

        getUrl: function () {
            return 'v2/runs/pcr';
        },

        getName: function () {
            return 'pcrrun';
        },
        getDynamicJson: function () {
            return 'PcrRunJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('runList.status'),
                name: 'status',
                classes: 'order-link',
                formatter: _.bind(this.statusFormatter, this),
                search: true,
                searchoptions: {clearSearch: false, size: 2},
                index: 'status',
                fixed: true,
                width: 60
            }, {
                label: _.i18n('runList.period'),
                name: 'periodFormatted',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'periodFormatted',
                fixed: true,
                width: 70
            }, {
                label: _.i18n('runList.group'),
                name: 'group',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'group',
                fixed: true,
                width: 50
            }, {
                label: _.i18n('user'),
                name: 'refRespUser',
                formatter: this.userFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'refRespUser.nickName',
                fixed: true,
                width: 75
            }, {
                label: _.i18n('plateSize'),
                name: 'plateSize',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'plateSize',
                fixed: true,
                width: 50
            }, {
                label: _.i18n('runList.name'),
                name: 'code',
                classes: 'order-link css-run-listView-accentuation',
                formatter: this.runNameFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'code',
                fixed: true,
                width: 160
            }, {
                label: _.i18n('runList.valStatus'),
                name: 'valStatus',
                classes: 'order-link',
                formatter: _.bind(this.valStatusFormatter, this),
                search: true,
                searchoptions: {clearSearch: false},
                index: 'valStatus',
                fixed: true,
                width: 60
            }, {
                label: _.i18n('runList.assays'),
                name: 'assays',
                classes: 'order-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                index: 'assays',
                searchoptions: {size: 40, clearSearch: false},
                fixed: true,
                width: 200
            }, {
                label: _.i18n('runList.refKitProt'),
                name: 'refKitProt.code',
                classes: 'order-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'kitProtCode',
                fixed: true,
                width: 160
            }, {
                label: _.i18n('runList.refCycler'),
                name: 'refCycler.code',
                classes: 'order-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'cyclerCode',
                fixed: true,
                width: 90
            }, {
                label: _.i18n('runList.refLmbCycler'),
                name: 'refLmbCycler.code',
                classes: 'order-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'refLmbCycler.code',
                fixed: true,
                width: 120
            }, {
                label: _.i18n('run.prepruns'),
                name: 'prepRuns',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'prepRunName',
                fixed: true,
                width: 200
            }, {
                label: _.i18n('runList.plateIdPC'),
                name: 'plateIdPC',
                formatter: this.plateIdFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'plateIdPC',
                fixed: true,
                width: 110
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'order-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'comment'
            });
            return columns;
        },

        plateIdFormatter: function (cellValue, options) {
            var idMaxLength = 12;
            var value = '', sub = 0;
            if (cellValue) {
                if (cellValue.length > idMaxLength) {
                    sub = cellValue.length - idMaxLength;
                    value = '...';
                }
                value += cellValue.substr(sub);
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + value + '</span>';
        },
        runNameFormatter: function (cellValue, options) {
            return '<span class="cell-default" style="font-weight: bold; " data-row-id="' +
                options.rowId + '">' + _.escape(cellValue) + '</span>';
        },
        valStatusFormatter: function (cellValue, options, object) {
            var status = parseInt(object.status, 10);
            var valStatus = object.valStatus;
            var iconColor = [];
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + valStatus + '</span>';
            var content = Marionette.Renderer.render(ValStatusToolTip, {
                codeList: this.options.codeListValStatus,
                valStatus: valStatus
            });

            statusIcon += '<div class="cell-default popover-status" style="display: flex; top: 2px;\n' +
                '            position: relative;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';

            var valStatusArray = valStatus.split('');
            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                if (valStatusArray.includes(i.toString())) {
                    iconColor[i] = 'statusIcon-toDo';
                }
                if (i === 5 && valStatusArray.includes(i.toString()) && status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-valStatusIcon ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },
        statusFormatter: function (cellValue, options) {
            var status = cellValue ? parseInt(cellValue, 10) : ' ';
            var iconColor = [];
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + status + '</span>';
            var content = Marionette.Renderer.render(StatusToolTip, {
                codeList: this.options.codeListStatus,
                status: status
            });

            statusIcon += '<div class="cell-default popover-status" style="display: flex; top: 2px;\n' +
                '            position: relative;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';

            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-statusIcon' + i + ' ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },
        getModel: function (runJSON) {
            if (runJSON) {
                return app.ns.PcrRun.findOrCreate(runJSON);
            }
            return new app.ns.PcrRun();
        },
        getRoute: function () {
            return '#runs/pcr';
        },
        parameterForLink: function (model) {
            return {
                secId: model.get('secId'),
                code: model.get('code'),
                name: model.get('name'),
                entity: model.getEntity()
            };
        },
        generateRunSheet: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('compuzz', 'v2/runs/pcr/report/PCR_RUN_SHEET', {secIds: secIds});
            window.open(url, '_blank');
        },
        generatePcrSetup: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('compuzz', 'v2/runs/pcr/report/PCR_SETUP', {secIds: secIds});
            window.open(url, '_blank');
        },
        generatePcrSetupMMX: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('compuzz', 'v2/runs/pcr/report/PCR_SETUP_MMX', {secIds: secIds});
            window.open(url, '_blank');
        },
        getAutocompleteParamForColorCompensation: function (config, context) {
            var configuration = Dynamic.getAutocompleteParam(config, context);
            configuration.canAddOrEdit = false;
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = context.getUrl() + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'desc',
                'sidx': 'creatDate',
                withColorCompensation: true
            }));
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['plateIdPC', 'refCycler.code', 'refLmbCycler.code']};
        },

        // create from popup
        importXmlFile: function (fileInfo, lmbCycler, kitProt) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {};

            if (lmbCycler) {
                param.lmbCyclerSecId = lmbCycler.get('secId');
            }
            if (kitProt) {
                param.kitProtSecId = kitProt.get('secId');
            }

            var url = Settings.url('compuzz', 'v2/runs/pcr', param);

            _.each(fileInfo, function (file) {
                formdata.append('file', file);
            });

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function () {
                    defer.reject();
                }
            });

            return defer.promise();
        },

        filesToImport: function () {

            var url = Settings.url('compuzz', 'import/result/files'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },

        createRunFromResult: function (files, cyclerSecId, respUserSecId) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    cyclerSecId: cyclerSecId,
                    respUserSecId: respUserSecId
                };

            var url = Settings.url('compuzz', 'v2/runs/pcr/fromResult', param);

            _.each(files, function (file) {
                formdata.append('file', file);
            });

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        deleteRun: function (secId) {
            var url = Settings.url('compuzz', 'v2/runs/pcr/' + secId),
                defer = $.Deferred();

            $.ajax({
                type: 'DELETE',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        canAddOrEdit: function () {
            return false;
        }
    }, Dynamic);
});
